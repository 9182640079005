/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAgreement = /* GraphQL */ `
  mutation CreateAgreement(
    $input: CreateAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    createAgreement(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      agreement_type
      agreement_accepted
      agreement_version
      agreement_response_datetime
      agreement_response_ip
      agreement_document_url
      agreement_document_uri
      agreement_document_arn
      agreement_document_etag
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAgreement = /* GraphQL */ `
  mutation UpdateAgreement(
    $input: UpdateAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    updateAgreement(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      agreement_type
      agreement_accepted
      agreement_version
      agreement_response_datetime
      agreement_response_ip
      agreement_document_url
      agreement_document_uri
      agreement_document_arn
      agreement_document_etag
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgreement = /* GraphQL */ `
  mutation DeleteAgreement(
    $input: DeleteAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    deleteAgreement(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      agreement_type
      agreement_accepted
      agreement_version
      agreement_response_datetime
      agreement_response_ip
      agreement_document_url
      agreement_document_uri
      agreement_document_arn
      agreement_document_etag
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_first_name
      customer_last_name
      customer_preferred_name
      customer_status
      customer_type
      customer_company_name
      customer_company_logo
      customer_company_brand_image
      customer_company_occupation
      customer_company_website
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_other
      customer_social_twitter
      customer_social_youtube
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_phone_work
      customer_referred_by
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        id
        email
        approved
        allowed_operations
      }
      agreements {
        nextToken
      }
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      carcasses {
        nextToken
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herds {
        nextToken
      }
      jetsons {
        nextToken
      }
      measurements {
        nextToken
      }
      notes {
        nextToken
      }
      phenotypes {
        nextToken
      }
      sites {
        nextToken
      }
      treatments {
        nextToken
      }
      videos {
        nextToken
      }
      wifi {
        nextToken
      }
      zones {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id

    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_first_name
      customer_last_name
      customer_preferred_name
      customer_status
      customer_type
      customer_company_name
      customer_company_logo
      customer_company_brand_image
      customer_company_occupation
      customer_company_website
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_other
      customer_social_twitter
      customer_social_youtube
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_phone_work
      customer_referred_by
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        id
        email
        approved
        allowed_operations
      }
      agreements {
        nextToken
      }
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      carcasses {
        nextToken
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herds {
        nextToken
      }
      jetsons {
        nextToken
      }
      measurements {
        nextToken
      }
      notes {
        nextToken
      }
      phenotypes {
        nextToken
      }
      sites {
        nextToken
      }
      treatments {
        nextToken
      }
      videos {
        nextToken
      }
      wifi {
        nextToken
      }
      zones {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      jetsons {
        nextToken
      }
      notes {
        nextToken
      }
      wifi {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      jetsons {
        nextToken
      }
      notes {
        nextToken
      }
      wifi {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      jetsons {
        nextToken
      }
      notes {
        nextToken
      }
      wifi {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAnimal = /* GraphQL */ `
  mutation CreateAnimal(
    $input: CreateAnimalInput!
    $condition: ModelAnimalConditionInput
  ) {
    createAnimal(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status

      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatments {
        nextToken
      }
      phenotypes {
        nextToken
      }
      measurements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAnimal = /* GraphQL */ `
  mutation UpdateAnimal(
    $input: UpdateAnimalInput!
    $condition: ModelAnimalConditionInput
  ) {
    updateAnimal(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatments {
        nextToken
      }
      phenotypes {
        nextToken
      }
      measurements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnimal = /* GraphQL */ `
  mutation DeleteAnimal(
    $input: DeleteAnimalInput!
    $condition: ModelAnimalConditionInput
  ) {
    deleteAnimal(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createCarcass = /* GraphQL */ `
  mutation CreateCarcass(
    $input: CreateCarcassInput!
    $condition: ModelCarcassConditionInput
  ) {
    createCarcass(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCarcass = /* GraphQL */ `
  mutation UpdateCarcass(
    $input: UpdateCarcassInput!
    $condition: ModelCarcassConditionInput
  ) {
    updateCarcass(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCarcass = /* GraphQL */ `
  mutation DeleteCarcass(
    $input: DeleteCarcassInput!
    $condition: ModelCarcassConditionInput
  ) {
    deleteCarcass(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTreatmentAnimals = /* GraphQL */ `
mutation CreateTreatmentAnimals(
  $input: CreateTreatmentAnimalsInput!
  $condition: ModelTreatmentAnimalsConditionInput
) {
  createTreatmentAnimals(input: $input, condition: $condition) {
    id

  }
}
`;

export const createAnimalTag = /* GraphQL */ `
mutation CreateAnimalTag(
  $input: CreateAnimalTagInput!
  $condition: ModelAnimalTagConditionInput
) {
  createAnimalTag(input: $input, condition: $condition) {
    id
  }
}
`;

export const updateAnimalTag = /* GraphQL */ `
  mutation UpdateAnimalTag(
    $input: UpdateAnimalTagInput!
    $condition: ModelAnimalTagConditionInput
  ) {
    updateAnimalTag(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateAnimalIdentification = /* GraphQL */ `
  mutation UpdateAnimalIdentification (
    $input: UpdateAnimalIdentificationInput!
    $condition: ModelAnimalIdentificationConditionInput
  ) {
    updateAnimalIdentification(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createAnimalGroups = /* GraphQL */ `
  mutation CreateAnimalGroups(
    $input: CreateAnimalGroupsInput!
    $condition: ModelAnimalGroupsConditionInput
  ) {
    createAnimalGroups(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAnimalGroups = /* GraphQL */ `
  mutation UpdateAnimalGroups(
    $input: UpdateAnimalGroupsInput!
    $condition: ModelAnimalGroupsConditionInput
  ) {
    updateAnimalGroups(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnimalGroups = /* GraphQL */ `
  mutation DeleteAnimalGroups(
    $input: DeleteAnimalGroupsInput!
    $condition: ModelAnimalGroupsConditionInput
  ) {
    deleteAnimalGroups(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHerd = /* GraphQL */ `
  mutation CreateHerd(
    $input: CreateHerdInput!
    $condition: ModelHerdConditionInput
  ) {
    createHerd(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      herd_name
      herd_date
      herd_tag_color
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHerd = /* GraphQL */ `
  mutation UpdateHerd(
    $input: UpdateHerdInput!
    $condition: ModelHerdConditionInput
  ) {
    updateHerd(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      herd_name
      herd_date
      herd_tag_color
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHerd = /* GraphQL */ `
  mutation DeleteHerd(
    $input: DeleteHerdInput!
    $condition: ModelHerdConditionInput
  ) {
    deleteHerd(input: $input, condition: $condition) {
      id

    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment(
    $input: CreateTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    createTreatment(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment(
    $input: UpdateTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    updateTreatment(input: $input, condition: $condition) {
      id

    }
  }
`;
export const deleteTreatment = /* GraphQL */ `
  mutation DeleteTreatment(
    $input: DeleteTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    deleteTreatment(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      treatment_date
      treatment_keywords
      treatment_type
      treatment_administered_drugs {
        administration_type
        administration_location
        dose
        lot_no
        name
        type
      }
      treatment_reason
      treatment_vet_contacted
      treatment_vet_name
      treatment_booster_date
      treatment_withdraw_date
      treatment_withdraw_days
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhenotype = /* GraphQL */ `
  mutation CreatePhenotype(
    $input: CreatePhenotypeInput!
    $condition: ModelPhenotypeConditionInput
  ) {
    createPhenotype(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      phenotype_date
      phenotype_score
      phenotype_type
      phenotype_image
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePhenotype = /* GraphQL */ `
  mutation UpdatePhenotype(
    $input: UpdatePhenotypeInput!
    $condition: ModelPhenotypeConditionInput
  ) {
    updatePhenotype(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      phenotype_date
      phenotype_score
      phenotype_type
      phenotype_image
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePhenotype = /* GraphQL */ `
  mutation DeletePhenotype(
    $input: DeletePhenotypeInput!
    $condition: ModelPhenotypeConditionInput
  ) {
    deletePhenotype(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteTreatmentAnimals = /* GraphQL */ `
mutation DeleteTreatmentAnimals(
  $input: DeleteTreatmentAnimalsInput!
  $condition: ModelTreatmentAnimalsConditionInput
) {
  deleteTreatmentAnimals(input: $input, condition: $condition) {
    id
  }
}
`;
export const createMeasurement = /* GraphQL */ `
  mutation CreateMeasurement(
    $input: CreateMeasurementInput!
    $condition: ModelMeasurementConditionInput
  ) {
    createMeasurement(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      measurement_date
      measurement_type
      measurement_unit
      measurement_value
      measurement_result
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMeasurement = /* GraphQL */ `
  mutation UpdateMeasurement(
    $input: UpdateMeasurementInput!
    $condition: ModelMeasurementConditionInput
  ) {
    updateMeasurement(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      measurement_date
      measurement_type
      measurement_unit
      measurement_value
      measurement_result
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeasurement = /* GraphQL */ `
  mutation DeleteMeasurement(
    $input: DeleteMeasurementInput!
    $condition: ModelMeasurementConditionInput
  ) {
    deleteMeasurement(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createCamera = /* GraphQL */ `
  mutation CreateCamera(
    $input: CreateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    createCamera(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      camera_footage_thumbnail_url
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_inventory_number
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCamera = /* GraphQL */ `
  mutation UpdateCamera(
    $input: UpdateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    updateCamera(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      camera_footage_thumbnail_url
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_inventory_number
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCamera = /* GraphQL */ `
  mutation DeleteCamera(
    $input: DeleteCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    deleteCamera(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      camera_footage_thumbnail_url
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_inventory_number
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJetson = /* GraphQL */ `
  mutation CreateJetson(
    $input: CreateJetsonInput!
    $condition: ModelJetsonConditionInput
  ) {
    createJetson(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJetson = /* GraphQL */ `
  mutation UpdateJetson(
    $input: UpdateJetsonInput!
    $condition: ModelJetsonConditionInput
  ) {
    updateJetson(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJetson = /* GraphQL */ `
  mutation DeleteJetson(
    $input: DeleteJetsonInput!
    $condition: ModelJetsonConditionInput
  ) {
    deleteJetson(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWifi = /* GraphQL */ `
  mutation CreateWifi(
    $input: CreateWifiInput!
    $condition: ModelWifiConditionInput
  ) {
    createWifi(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_inventory_number
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWifi = /* GraphQL */ `
  mutation UpdateWifi(
    $input: UpdateWifiInput!
    $condition: ModelWifiConditionInput
  ) {
    updateWifi(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_inventory_number
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWifi = /* GraphQL */ `
  mutation DeleteWifi(
    $input: DeleteWifiInput!
    $condition: ModelWifiConditionInput
  ) {
    deleteWifi(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_inventory_number
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      camera_id
      group_id
      herd_id
      jetson_id
      site_id
      video_id
      zone_id
      event_datetime_start
      event_datetime_end
      event_level
      event_title
      event_color
      event_type
      event_description
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      video {
        id
        owner
        updatedBy
        customer_id
        camera_id
        camera_id_datastore
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_size_mb
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      camera_id
      group_id
      herd_id
      jetson_id
      site_id
      video_id
      zone_id
      event_datetime_start
      event_datetime_end
      event_level
      event_title
      event_color
      event_type
      event_description
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      video {
        id
        owner
        updatedBy
        customer_id
        camera_id
        camera_id_datastore
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_size_mb
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      camera_id
      group_id
      herd_id
      jetson_id
      site_id
      video_id
      zone_id
      event_datetime_start
      event_datetime_end
      event_level
      event_title
      event_color
      event_type
      event_description
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      video {
        id
        owner
        updatedBy
        customer_id
        camera_id
        camera_id_datastore
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_size_mb
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
 
      wifi_id
     
      createdAt
      updatedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
      zone_id
      camera_id
      carcass_id
      group_id
      herd_id
      jetson_id
      site_id
      treatment_id
      phenotype_id
      measurement_id
      wifi_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
      zone_id
      camera_id
      carcass_id
      group_id
      herd_id
      jetson_id
      site_id
      treatment_id
      phenotype_id
      measurement_id
      wifi_id
      createdAt
      updatedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      camera_id_datastore
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_size_mb
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      camera_id_datastore
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_size_mb
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      camera_id_datastore
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_size_mb
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;