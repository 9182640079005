/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_company_occupation
      customer_last_name
      customer_first_name
      customer_preferred_name
      customer_type
      customer_company_name
      customer_company_logo
      customer_company_brand_image
      customer_company_website
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_other
      customer_social_twitter
      customer_social_youtube
      customer_initial_login_web
      customer_notification_preference {
        alert {
          call_frequency
          email_frequency
          sms_frequency
        }
        info {
          call_frequency
          email_frequency
          sms_frequency
        }
        warning {
          call_frequency
          email_frequency
          sms_frequency
        }
      }
      agreements {
        items {
          id
          owner

  updatedBy
  customer_id
  

  agreement_type
  agreement_accepted
  agreement_version
  agreement_response_datetime
        }
        
      }
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_phone_home
      customer_phone_cell
      customer_phone_fax

      customer_collaborators {
        id
        approved
        allowed_operations
        email
        notifications_enabled
      }
      customer_is_collaborator {
        id
        approved
        allowed_operations
        email
        notifications_enabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const getCollabCustomerCompanyName = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      customer_company_name
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $id: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: [SearchableCustomerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomerAggregationInput]
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
        
      }
      cameras {
        nextToken
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }
      jetsons {
        nextToken
        
      }
      notes {
        nextToken
        
      }
      wifi {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $id: ID
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSites(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncSites = /* GraphQL */ `
  query SyncSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listSitesByCustomer = /* GraphQL */ `
  query ListSitesByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSitesByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchSites = /* GraphQL */ `
  query SearchSites(
    $filter: SearchableSiteFilterInput
    $sort: [SearchableSiteSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSiteAggregationInput]
  ) {
    searchSites(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $id: ID
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZones(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        zone_type
        zone_area_square_meters
        zone_color
        zone_name
        zone_perimiter {
          latitude
          longitude
        }
        zone_perimeter_meters
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncZones = /* GraphQL */ `
  query SyncZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        zone_type
        zone_area_square_meters
        zone_color
        zone_name
        zone_perimeter_meters
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listZonesByCustomer = /* GraphQL */ `
  query ListZonesByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZonesByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        zone_type
        zone_area_square_meters
        zone_color
        zone_name
        zone_perimiter {
          latitude
          longitude
        }
        zone_perimeter_meters
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchZones = /* GraphQL */ `
  query SearchZones(
    $filter: SearchableZoneFilterInput
    $sort: [SearchableZoneSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableZoneAggregationInput]
  ) {
    searchZones(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        zone_type
        zone_area_square_meters
        zone_color
        zone_name
        zone_perimeter_meters
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAnimal = /* GraphQL */ `
  query GetAnimal($id: ID!) {
    getAnimal(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
    #   animal_identifications(sortDirection: ASC) {
    #   items {
    #     animal_identification_review_status
    #     id
    #     animal_identification_datetime
    #     animalIdentificationAnimal_identification_inference_tagId
    #     animalIdentificationAnimal_identification_inference_headId
    #     animalIdentificationAnimal_identification_inference_bodyId
    #   }
    # }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
      animal_tag_history {
        current
        name
        number
        letter
        color
        date
      }
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }

      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        

      }
      notes {
        nextToken
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }

      phenotypes {
        items {
          phenotype_type
          phenotype_score
          phenotype_image
          phenotype_date
          id
        }      
      }
      measurements {
        items {
          measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
          
          id
        }
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listAnimals = /* GraphQL */ `
  query ListAnimals(
    $id: ID
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnimals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;

export const listAnimalIdentificationsByCustomerReviewStatus = /* GraphQL */ `
  query ListAnimalIdentificationsByCustomerReviewStatus(
    $filter: ModelAnimalIdentificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $animal_identification_review_statusAnimal_identification_datetime: ModelAnimalIdentificationAnimalIdentificationsByCustomerReviewStatusCompositeKeyConditionInput
    $customer_id: ID!
  ) {
    listAnimalIdentificationsByCustomerReviewStatus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      animal_identification_review_statusAnimal_identification_datetime: $animal_identification_review_statusAnimal_identification_datetime
      customer_id: $customer_id
    ) {
      items {
        id
        animal_identification_review_status
        animal_identification_review {
        approved
        comment
        datetime
        reviewed_by
      }
      owner
      image_id
      animal_identification_datetime
      animal_id
      animalIdentificationAnimal_identification_inference_tagId
      animalIdentificationAnimal_identification_inference_headId
      animalIdentificationAnimal_identification_inference_bodyId
      animal_identification_inference_tag {
        inference_value
        inference_tracker
        inference_model_version
        inference_model_type
        inference_model_name
        inference_datetime
        inference_confidence
      }
        createdAt
        updatedAt   
        
      }
      nextToken
      
    }
  }
`;

export const listAnimalTagsByReviewStatus = /* GraphQL */ `
  query ListAnimalTagsByReviewStatus(
    $filter: ModelAnimalTagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $customer_id: ID!
    $animal_tag_review_statusAnimal_tag_date: ModelAnimalTagAnimalTagsByReviewStatusCompositeKeyConditionInput
  ) {
    listAnimalTagsByReviewStatus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      customer_id: $customer_id
      animal_tag_review_statusAnimal_tag_date: $animal_tag_review_statusAnimal_tag_date
    ) {
      items {
      owner
      id
      customer_id
      createdAt
      animal_tag_year_code
      animal_tag_text
      animal_tag_source
      animal_tag_review_status
      animal_tag_number
      animal_tag_letter
      animal_tag_date
      animal_tag_current
      animal_tag_color
      animal_id
    }
      nextToken
      
    }
  }
`;

export const syncAnimals = /* GraphQL */ `
  query SyncAnimals(
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnimals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsByCustomer = /* GraphQL */ `
  query ListAnimalsByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
      animal_tag_history {
        current
        name
        number
        letter
        color
        date
      }
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsByDam = /* GraphQL */ `
  query ListAnimalsByDam(
    $dam_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByDam(
      dam_id: $dam_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsByHerd = /* GraphQL */ `
  query ListAnimalsByHerd(
    $herd_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByHerd(
      herd_id: $herd_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
      animal_tag_history {
        current
        name
        number
        letter
        color
        date
      }
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsBySire = /* GraphQL */ `
  query ListAnimalsBySire(
    $sire_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsBySire(
      sire_id: $sire_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsBySite = /* GraphQL */ `
  query ListAnimalsBySite(
    $site_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsBySite(
      site_id: $site_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsBySurrogateDam = /* GraphQL */ `
  query ListAnimalsBySurrogateDam(
    $surrogate_dam_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsBySurrogateDam(
      surrogate_dam_id: $surrogate_dam_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsByZone = /* GraphQL */ `
  query ListAnimalsByZone(
    $zone_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByZone(
      zone_id: $zone_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalsByRFID = /* GraphQL */ `
  query ListAnimalsByRFID(
    $animal_rfid_current: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByRFID(
      animal_rfid_current: $animal_rfid_current
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchAnimals = /* GraphQL */ `
  query SearchAnimals(
    $filter: SearchableAnimalFilterInput
    $sort: [SearchableAnimalSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAnimalAggregationInput]
  ) {
    searchAnimals(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCarcass = /* GraphQL */ `
  query GetCarcass($id: ID!) {
    getCarcass(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listCarcasses = /* GraphQL */ `
  query ListCarcasses(
    $id: ID
    $filter: ModelCarcassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCarcasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        animal_id
        carcass_slaughter_date
        carcass_base_price
        carcass_fat_thickness
        carcass_marbling_score
        carcass_maturity
        carcass_quality_grade
        carcass_ribeye_area
        carcass_us_bf
        carcass_us_marbling
        carcass_us_rea
        carcass_value
        carcass_weight_cold_kgs
        carcass_weight_cold_lbs
        carcass_weight_hot_kgs
        carcass_weight_hot_lbs
        carcass_yield_grade
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncCarcasses = /* GraphQL */ `
  query SyncCarcasses(
    $filter: ModelCarcassFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarcasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        animal_id
        carcass_slaughter_date
        carcass_base_price
        carcass_fat_thickness
        carcass_marbling_score
        carcass_maturity
        carcass_quality_grade
        carcass_ribeye_area
        carcass_us_bf
        carcass_us_marbling
        carcass_us_rea
        carcass_value
        carcass_weight_cold_kgs
        carcass_weight_cold_lbs
        carcass_weight_hot_kgs
        carcass_weight_hot_lbs
        carcass_yield_grade
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listCarcassesByCustomer = /* GraphQL */ `
  query ListCarcassesByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCarcassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarcassesByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        animal_id
        carcass_slaughter_date
        carcass_base_price
        carcass_fat_thickness
        carcass_marbling_score
        carcass_maturity
        carcass_quality_grade
        carcass_ribeye_area
        carcass_us_bf
        carcass_us_marbling
        carcass_us_rea
        carcass_value
        carcass_weight_cold_kgs
        carcass_weight_cold_lbs
        carcass_weight_hot_kgs
        carcass_weight_hot_lbs
        carcass_yield_grade
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listCarcassesBySite = /* GraphQL */ `
  query ListCarcassesBySite(
    $site_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCarcassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarcassesBySite(
      site_id: $site_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        animal_id
        carcass_slaughter_date
        carcass_base_price
        carcass_fat_thickness
        carcass_marbling_score
        carcass_maturity
        carcass_quality_grade
        carcass_ribeye_area
        carcass_us_bf
        carcass_us_marbling
        carcass_us_rea
        carcass_value
        carcass_weight_cold_kgs
        carcass_weight_cold_lbs
        carcass_weight_hot_kgs
        carcass_weight_hot_lbs
        carcass_yield_grade
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchCarcasses = /* GraphQL */ `
  query SearchCarcasses(
    $filter: SearchableCarcassFilterInput
    $sort: [SearchableCarcassSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCarcassAggregationInput]
  ) {
    searchCarcasses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        animal_id
        carcass_slaughter_date
        carcass_base_price
        carcass_fat_thickness
        carcass_marbling_score
        carcass_maturity
        carcass_quality_grade
        carcass_ribeye_area
        carcass_us_bf
        carcass_us_marbling
        carcass_us_rea
        carcass_value
        carcass_weight_cold_kgs
        carcass_weight_cold_lbs
        carcass_weight_hot_kgs
        carcass_weight_hot_lbs
        carcass_yield_grade
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      animals {
        items {
          
          animal {
            id
            animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
      animal_tag_history {
        current
        name
        number
        letter
        color
        date
      }
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
          }
        }
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
        
      }
      events {
        nextToken
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $id: ID
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listGroupsByCustomer = /* GraphQL */ `
  query ListGroupsByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchGroups = /* GraphQL */ `
  query SearchGroups(
    $filter: SearchableGroupFilterInput
    $sort: [SearchableGroupSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGroupAggregationInput]
  ) {
    searchGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAnimalGroups = /* GraphQL */ `
  query GetAnimalGroups($id: ID!) {
    getAnimalGroups(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listAnimalGroups = /* GraphQL */ `
  query ListAnimalGroups(
    $id: ID
    $filter: ModelAnimalGroupsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnimalGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group { 
            group_name
        }
        id
        owner
        updatedBy
        animal_id
        group_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncAnimalGroups = /* GraphQL */ `
  query SyncAnimalGroups(
    $filter: ModelAnimalGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnimalGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        group_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalGroupByAnimal = /* GraphQL */ `
  query ListAnimalGroupByAnimal(
    $animal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalGroupByAnimal(
      animal_id: $animal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        group_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listAnimalGroupByGroup = /* GraphQL */ `
  query ListAnimalGroupByGroup(
    $group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalGroupByGroup(
      group_id: $group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group {
          group_name
        }
        id
        owner
        updatedBy
        animal_id
        group_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const getHerd = /* GraphQL */ `
  query GetHerd($id: ID!) {
    getHerd(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      herd_name
      herd_date
      herd_tag_color
      animals {
        items {
          id
        }
      }
      events {
        nextToken
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listHerds = /* GraphQL */ `
  query ListHerds(
    $id: ID
    $filter: ModelHerdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHerds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncHerds = /* GraphQL */ `
  query SyncHerds(
    $filter: ModelHerdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHerds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listHerdsByCustomer = /* GraphQL */ `
  query ListHerdsByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHerdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHerdsByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchHerds = /* GraphQL */ `
  query SearchHerds(
    $filter: SearchableHerdFilterInput
    $sort: [SearchableHerdSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHerdAggregationInput]
  ) {
    searchHerds(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      treatment_date
      treatment_keywords
      treatment_type
      treatment_administered_drugs {
        administration_type
        administration_location
        dose
        lot_no
        name
        type
      }
      treatment_reason
      treatment_vet_contacted
      treatment_vet_name
      treatment_booster_date
      treatment_withdraw_date
      treatment_withdraw_days
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listTreatments = /* GraphQL */ `
  query ListTreatments(
    $id: ID
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTreatments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        group_id
        herd_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncTreatments = /* GraphQL */ `
  query SyncTreatments(
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTreatments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listTreatmentsByCustomer = /* GraphQL */ `
  query ListTreatmentsByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentsByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        animals {
        items {
          animal {
            tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
          }
        }
      }
        id
        owner
        updatedBy
        customer_id
        
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listTreatmentsByAnimal = /* GraphQL */ `
  query ListTreatmentsByAnimal(
    $animal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentsByAnimal(
      animal_id: $animal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
      treatment {
        id
        treatment_booster_date
        treatment_date
        treatment_keywords
        treatment_reason
        treatment_type
        treatment_vet_contacted
        treatment_vet_name
        treatment_withdraw_date
        treatment_withdraw_days
      }
    }
      nextToken
      
    }
  }
`;
export const listAnimalsByTreatment = /* GraphQL */ `
  query ListTreatmentsByAnimal(
    $treatment_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentAnimalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByTreatment(
      treatment_id: $treatment_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        animal {
        id
        tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
      }
    }
      nextToken
      
    }
  }
`;
export const searchTreatments = /* GraphQL */ `
  query SearchTreatments(
    $filter: SearchableTreatmentFilterInput
    $sort: [SearchableTreatmentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTreatmentAggregationInput]
  ) {
    searchTreatments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPhenotype = /* GraphQL */ `
  query GetPhenotype($id: ID!) {
    getPhenotype(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      phenotype_date
      phenotype_score
      phenotype_type
      phenotype_image
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listPhenotypes = /* GraphQL */ `
  query ListPhenotypes(
    $id: ID
    $filter: ModelPhenotypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhenotypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncPhenotypes = /* GraphQL */ `
  query SyncPhenotypes(
    $filter: ModelPhenotypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhenotypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listPhenotypesByCustomer = /* GraphQL */ `
  query ListPhenotypesByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPhenotypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhenotypesByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        animal {
          groups {
          items {
            group_id
          }
        }
        id
        tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listPhenotypesByAnimal = /* GraphQL */ `
  query ListPhenotypesByAnimal(
    $animal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPhenotypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhenotypesByAnimal(
      animal_id: $animal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchPhenotypes = /* GraphQL */ `
  query SearchPhenotypes(
    $filter: SearchablePhenotypeFilterInput
    $sort: [SearchablePhenotypeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhenotypeAggregationInput]
  ) {
    searchPhenotypes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMeasurement = /* GraphQL */ `
  query GetMeasurement($id: ID!) {
    getMeasurement(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      measurement_date
      measurement_type
      measurement_unit
      measurement_value
      measurement_result
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listMeasurements = /* GraphQL */ `
  query ListMeasurements(
    $id: ID
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeasurements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncMeasurements = /* GraphQL */ `
  query SyncMeasurements(
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeasurements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listMeasurementsByAnimal = /* GraphQL */ `
  query ListMeasurementsByAnimal(
    $animal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurementsByAnimal(
      animal_id: $animal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
        animal {
          id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
      animal_tag_history {
        current
        name
        number
        letter
        color
        date
      }
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }
      groups {
        nextToken
        
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      treatments {
        nextToken
        
      }
      phenotypes {
        nextToken
        
      }
      measurements {
        nextToken
        
      }
      createdAt
      updatedAt
        }
        
        
      }
      nextToken
      
    }
  }
`;
export const listMeasurementsByCustomer = /* GraphQL */ `
  query ListMeasurementsByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurementsByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        animal {
          tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchMeasurements = /* GraphQL */ `
  query SearchMeasurements(
    $filter: SearchableMeasurementFilterInput
    $sort: [SearchableMeasurementSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMeasurementAggregationInput]
  ) {
    searchMeasurements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCamera = /* GraphQL */ `
  query GetCamera($id: ID!) {
    getCamera(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }
      notes {
        nextToken
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      videos {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listCameras = /* GraphQL */ `
  query ListCameras(
    $id: ID
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCameras(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncCameras = /* GraphQL */ `
  query SyncCameras(
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCameras(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listCamerasByCustomer = /* GraphQL */ `
  query ListCamerasByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCamerasByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listCamerasBySite = /* GraphQL */ `
  query ListCamerasBySite(
    $site_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCamerasBySite(
      site_id: $site_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchCameras = /* GraphQL */ `
  query SearchCameras(
    $filter: SearchableCameraFilterInput
    $sort: [SearchableCameraSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCameraAggregationInput]
  ) {
    searchCameras(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getJetson = /* GraphQL */ `
  query GetJetson($id: ID!) {
    getJetson(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }
      notes {
        nextToken
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listJetsons = /* GraphQL */ `
  query ListJetsons(
    $id: ID
    $filter: ModelJetsonFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJetsons(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncJetsons = /* GraphQL */ `
  query SyncJetsons(
    $filter: ModelJetsonFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJetsons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listJetsonsByCustomer = /* GraphQL */ `
  query ListJetsonsByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJetsonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJetsonsByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listJetsonsBySite = /* GraphQL */ `
  query ListJetsonsBySite(
    $site_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJetsonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJetsonsBySite(
      site_id: $site_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchJetsons = /* GraphQL */ `
  query SearchJetsons(
    $filter: SearchableJetsonFilterInput
    $sort: [SearchableJetsonSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableJetsonAggregationInput]
  ) {
    searchJetsons(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getWifi = /* GraphQL */ `
  query GetWifi($id: ID!) {
    getWifi(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      notes {
        nextToken
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listWifis = /* GraphQL */ `
  query ListWifis(
    $id: ID
    $filter: ModelWifiFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWifis(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncWifis = /* GraphQL */ `
  query SyncWifis(
    $filter: ModelWifiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWifis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
        wifi_manufacturer
        wifi_model
        wifi_download_speed_mbps
        wifi_email
        wifi_fixed
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_map_icon_color
        wifi_mesh_speed
        wifi_ping_time
        wifi_serial_no
        wifi_serial_number
        wifi_shipped
        wifi_status
        wifi_upload_speed_mbps
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listWifiByCustomer = /* GraphQL */ `
  query ListWifiByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWifiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWifiByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listWifiBySite = /* GraphQL */ `
  query ListWifiBySite(
    $site_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWifiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWifiBySite(
      site_id: $site_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchWifis = /* GraphQL */ `
  query SearchWifis(
    $filter: SearchableWifiFilterInput
    $sort: [SearchableWifiSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableWifiAggregationInput]
  ) {
    searchWifis(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
        wifi_manufacturer
        wifi_model
        wifi_download_speed_mbps
        wifi_email
        wifi_fixed
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_map_icon_color
        wifi_mesh_speed
        wifi_ping_time
        wifi_serial_no
        wifi_serial_number
        wifi_shipped
        wifi_status
        wifi_upload_speed_mbps
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      camera_id
      group_id
      herd_id
      jetson_id
      site_id
      video_id
      zone_id
      event_datetime_start
      event_datetime_end
      
      event_title
      event_color
      event_type
      event_description
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      video {
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const listNotificationsByCustomer = /* GraphQL */ `
  query ListNotificationsByCustomer(
    $customer_id: ID!
    $notification_datetime: ModelStringKeyConditionInput
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotificationsByCustomer(
      customer_id: $customer_id
      notification_datetime: $notification_datetime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id

        camera {
          camera_name
          id
        }
        notification_sent_datetime_twilio
        notification_twilio_sid
        notification_datetime
        notification_level
        notification_title
        notification_type
        notification_description
        notification_read
        notification_value
        notification_media_url

        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByAnimal = /* GraphQL */ `
  query ListEventsByAnimal(
    $animal_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByAnimal(
      animal_id: $animal_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByCustomer = /* GraphQL */ `
  query ListEventsByCustomer(
    $customer_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByCustomer(
      customer_id: $customer_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByCamera = /* GraphQL */ `
  query ListEventsByCamera(
    $camera_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByCamera(
      camera_id: $camera_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByGroup = /* GraphQL */ `
  query ListEventsByGroup(
    $group_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByGroup(
      group_id: $group_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByHerd = /* GraphQL */ `
  query ListEventsByHerd(
    $herd_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByHerd(
      herd_id: $herd_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByJetson = /* GraphQL */ `
  query ListEventsByJetson(
    $jetson_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByJetson(
      jetson_id: $jetson_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsBySite = /* GraphQL */ `
  query ListEventsBySite(
    $site_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsBySite(
      site_id: $site_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByVideo = /* GraphQL */ `
  query ListEventsByVideo(
    $video_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByVideo(
      video_id: $video_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByZone = /* GraphQL */ `
  query ListEventsByZone(
    $zone_id: ID!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByZone(
      zone_id: $zone_id
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listEventsByLevel = /* GraphQL */ `
  query ListEventsByLevel(
    $event_level: EventLevel!
    $event_datetime_start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByLevel(
      event_level: $event_level
      event_datetime_start: $event_datetime_start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: [SearchableEventSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventAggregationInput]
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        animal_id
        customer_id
        camera_id
        group_id
        herd_id
        jetson_id
        site_id
        video_id
        zone_id
        event_datetime_start
        event_datetime_end
        
        event_title
        event_color
        event_type
        event_description
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $id: ID
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeedbacks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        customer_id
        feedback_body
        feedback_type
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncFeedbacks = /* GraphQL */ `
  query SyncFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        customer_id
        feedback_body
        feedback_type
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchFeedbacks = /* GraphQL */ `
  query SearchFeedbacks(
    $filter: SearchableFeedbackFilterInput
    $sort: [SearchableFeedbackSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFeedbackAggregationInput]
  ) {
    searchFeedbacks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        customer_id
        feedback_body
        feedback_type
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
      zone_id
      camera_id
      carcass_id
      group_id
      herd_id
      jetson_id
      site_id
      treatment_id
      phenotype_id
      measurement_id
      wifi_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
        
        
        
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
        
        
        
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
        
        
        
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
        
        
        
      }
      treatment {
        id
        owner
        updatedBy
        customer_id
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
        
        
        
      }
      phenotype {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
        
        
        
      }
      measurement {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
        
        
        
      }
      wifi {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
        wifi_manufacturer
        wifi_model
        wifi_download_speed_mbps
        wifi_email
        wifi_fixed
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_map_icon_color
        wifi_mesh_speed
        wifi_ping_time
        wifi_serial_no
        wifi_serial_number
        wifi_shipped
        wifi_status
        wifi_upload_speed_mbps
        createdAt
        updatedAt
        
        
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $id: ID
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        author {
        id
        first_name
        last_name
        email
      }
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByCustomer = /* GraphQL */ `
  query ListNotesByCustomer(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByCustomer(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        author {
        id
        first_name
        last_name
        email
      }
        id
        animal {
          tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }

      }
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByAnimal = /* GraphQL */ `
  query ListNotesByAnimal(
    $animal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByAnimal(
      animal_id: $animal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByZone = /* GraphQL */ `
  query ListNotesByZone(
    $zone_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByZone(
      zone_id: $zone_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByCamera = /* GraphQL */ `
  query ListNotesByCamera(
    $camera_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByCamera(
      camera_id: $camera_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByCarcass = /* GraphQL */ `
  query ListNotesByCarcass(
    $carcass_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByCarcass(
      carcass_id: $carcass_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByGroup = /* GraphQL */ `
  query ListNotesByGroup(
    $group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByGroup(
      group_id: $group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByHerd = /* GraphQL */ `
  query ListNotesByHerd(
    $herd_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByHerd(
      herd_id: $herd_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByJetson = /* GraphQL */ `
  query ListNotesByJetson(
    $jetson_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByJetson(
      jetson_id: $jetson_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesBySite = /* GraphQL */ `
  query ListNotesBySite(
    $site_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesBySite(
      site_id: $site_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByTreatment = /* GraphQL */ `
  query ListNotesByTreatment(
    $treatment_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByTreatment(
      treatment_id: $treatment_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByPhenotype = /* GraphQL */ `
  query ListNotesByPhenotype(
    $phenotype_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByPhenotype(
      phenotype_id: $phenotype_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByMeasurement = /* GraphQL */ `
  query ListNotesByMeasurement(
    $measurement_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByMeasurement(
      measurement_id: $measurement_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listNotesByWifi = /* GraphQL */ `
  query ListNotesByWifi(
    $wifi_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotesByWifi(
      wifi_id: $wifi_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchNotes = /* GraphQL */ `
  query SearchNotes(
    $filter: SearchableNoteFilterInput
    $sort: [SearchableNoteSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNoteAggregationInput]
  ) {
    searchNotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        typeName
        note_datetime
        note_text
        animal_id
        zone_id
        camera_id
        carcass_id
        group_id
        herd_id
        jetson_id
        site_id
        treatment_id
        phenotype_id
        measurement_id
        wifi_id
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
        
        
        
      }
      customer {
        id
        owner
        updatedBy
        customer_last_name
        customer_first_name
        customer_preferred_name
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        createdAt
        updatedAt
        
        
        
      }
      events {
        nextToken
        
      }
      createdAt
      updatedAt
      
      
      
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $id: ID
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const syncVideos = /* GraphQL */ `
  query SyncVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listVideosByCustomer = /* GraphQL */ `
  query ListVideosByCustomer(
    $customer_id: ID!
    $video_datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByCustomer(
      customer_id: $customer_id
      video_datetime: $video_datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        camera {
          id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
        }
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listVideosByCustomerCameraDate = /* GraphQL */ `
  query ListVideosByCustomerCameraDate(
    $customer_id: ID!
    $camera_idVideo_datetime: ModelVideoVideosByCustomerCameraDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByCustomerCameraDate(
      customer_id: $customer_id
      camera_idVideo_datetime: $camera_idVideo_datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        camera {
          id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
        }
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listVideosByCustomerDateCamera = /* GraphQL */ `
  query ListVideosByCustomerDateCamera(
    $customer_id: ID!
    $video_datetimeCamera_id: ModelVideoVideosByCustomerDateCameraCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByCustomerDateCamera(
      customer_id: $customer_id
      video_datetimeCamera_id: $video_datetimeCamera_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listVideosByCamera = /* GraphQL */ `
  query ListVideosByCamera(
    $camera_id: ID!
    $video_datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByCamera(
      camera_id: $camera_id
      video_datetime: $video_datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const listVideosByDateTime = /* GraphQL */ `
  query ListVideosByDateTime(
    $video_datetime: AWSDateTime!
    $camera_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByDateTime(
      video_datetime: $video_datetime
      camera_id: $camera_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        updatedBy
        customer_id
        camera_id
        
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
        
        
        
      }
      nextToken
      
    }
  }
`;
export const searchVideos = /* GraphQL */ `
  query SearchVideos(
    $filter: SearchableVideoFilterInput
    $sort: [SearchableVideoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableVideoAggregationInput]
  ) {
    searchVideos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {

      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listPhenotypesByHerd = /* GraphQL */ `
  query ListAnimalsByHerd(
    $herd_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByHerd(
      herd_id: $herd_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phenotypes {
        items {
          phenotype_type
          phenotype_score
          phenotype_image
          phenotype_date
          
          id
          animal {
          groups {
          items {
            group_id
          }
        }
        id
        tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
        }
      }
      }
      nextToken
      
    }
  }
`;
export const listMeasurementsByHerd = /* GraphQL */ `
  query ListAnimalsByHerd(
    $herd_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByHerd(
      herd_id: $herd_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        measurements {
        items {
          measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
          
          id
          animal {
          groups {
          items {
            group_id
          }
        }
        id
        tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
        }
      }
      }
      nextToken
      
    }
  }
`;
export const listTreatmentsByHerd = /* GraphQL */ `
  query ListAnimalsByHerd(
    $herd_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnimalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnimalsByHerd(
      herd_id: $herd_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        treatments {
        items {
          animal {
          groups {
          items {
            group_id
          }
        }
        id
        tags(sortDirection: DESC) {
        items {
          id
          animal_tag_text
          animal_tag_date
          animal_tag_color
        }
      }
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
        
        
        
      }
        id
        owner
        updatedBy
        
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
        
        
        
        }
      }
      }
      nextToken
      
    }
  }
`;
export const listTagColors = /* GraphQL */ `
  query ListTagColors { __type(name:"TagColor"){ name enumValues{ name } } }
`;
export const listProductionType = /* GraphQL */ `
  query ListProductionType { __type(name:"ProductionType"){ name enumValues{ name } } }
`;
export const listSexClassification = /* GraphQL */ `
  query ListSexClassification { __type(name:"SexClassification"){ name enumValues{ name } } }
`;
export const listAnimalPurpose = /* GraphQL */ `
  query ListAnimalPurpose { __type(name:"AnimalPurpose"){ name enumValues{ name } } }
`;
export const listOwnershipStatus = /* GraphQL */ `
  query ListOwnershipStatus { __type(name:"OwnershipStatus"){ name enumValues{ name } } }
`;
export const listBirthEase = /* GraphQL */ `
  query ListBirthEase { __type(name:"BirthEase"){ name enumValues{ name } } }
`;
export const listNurseInfo = /* GraphQL */ `
  query ListNurseInfo { __type(name:"NurseInfo"){ name enumValues{ name } } }
`;
export const listDamStatus = /* GraphQL */ `
  query ListDamStatus { __type(name:"DamStatus"){ name enumValues{ name } } }
`;

export const listTreatmentType = /* GraphQL */ `
  query ListTreatmentType { __type(name:"TreatmentType"){ name enumValues{ name } } }
`;
export const listDrugAdministrationType = /* GraphQL */ `
  query ListDrugAdministrationType { __type(name:"DrugAdministrationType"){ name enumValues{ name } } }
`;
export const listDrugType = /* GraphQL */ `
  query ListDrugType { __type(name:"DrugType"){ name enumValues{ name } } }
`;

export const listMeasurementType = /* GraphQL */ `
  query ListMeasurementType { __type(name:"MeasurementType"){ name enumValues{ name } } }
`;

export const listPhenotypeType = /* GraphQL */ `
  query ListPhenotypeType { __type(name:"PhenotypeType"){ name enumValues{ name } } }
`;

export const listZoneType = /* GraphQL */ `
  query ListZoneType { __type(name:"ZoneType"){ name enumValues{ name } } }
`;

export const listEventType = /* GraphQL */ `
  query ListEventType { __type(name:"EventType"){ name enumValues{ name } } }
`;
export const listAnimalStatus = /* GraphQL */ `
  query ListAnimalStatus { __type(name:"AnimalStatus"){ name enumValues{ name } } }
`;