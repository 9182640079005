<template>
  <div>
    <div class="d-flex justify-space-between" style="width: 100%">
      <div class="d-flex">
        <v-switch
          dense
          hide-details
          v-model="showbos"
          class="mt-0 mr-1"
          label="body"
        >
        </v-switch>
        <v-switch
          dense
          hide-details
          v-model="bodyparts"
          class="mt-0 mr-1"
          label="body parts"
        >
        </v-switch>
        <v-switch
          dense
          hide-details
          v-model="keypoints"
          class="mt-0 mr-1"
          label="keypoints"
        >
        </v-switch>
        <v-switch
          dense
          hide-details
          v-model="labels"
          class="mt-0 mr-1"
          label="labels"
        >
        </v-switch>
        <v-switch
          dense
          hide-details
          v-model="show_classification"
          class="mt-0 mr-1"
          label="classification"
        >
        </v-switch>

      </div>
    </div>
    <div class="love_container mt-2">
      <!-- <div class="d-flex" style="width: 100%">
            <div class="d-flex">
              <v-switch v-if="has_contraction"
                dense
                hide-details
                v-model="show_contraction"
                class="mt-0 mr-1"
                label="contraction"
              >
              </v-switch>
              <v-switch v-if="has_activity"
                dense
                hide-details
                v-model="show_activity"
                class="mt-0 mr-1"
                label="activity"
              >
              </v-switch>
              <v-switch v-if="has_age"
                dense
                hide-details
                v-model="show_age"
                class="mt-0 mr-1"
                label="age"
              >
              </v-switch>
              <v-switch v-if="has_suckling"
                dense
                hide-details
                v-model="show_suckling"
                class="mt-0 mr-1"
                label="suckling"
              >
              </v-switch>
              <v-switch
                dense
                hide-details
                v-model="show_filtered"
                class="mt-0 mr-1"
                label="filtered content"
              >
              </v-switch>
            </div>
          </div> -->
      <div id="image_card_annotation_area">
        <canvas id="image_card_main_canvas" ref="mainCanvas"></canvas>
      </div>

      <vue-load-image v-if="image_path">
        <img
          slot="image"
          alt=""
          :src="`${image_path}`"
          ref="image"
          class="grid_img_f"
        />
        <img slot="preloader" src="@/assets/loader.png" class="grid_img_f" />
        <div slot="error" class="no-image">
          <div>No<br />Image</div>
        </div>
      </vue-load-image>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main";
import JSZip from "jszip";
import VueLoadImage from "vue-load-image";

export default {
  components: {
    "vue-load-image": VueLoadImage,
  },
  props: ["img_src"],
  data() {
    return {
      json_loading: false,
      image_path: "",
      json_annotation_div: null,
      json_data: null,
      camera_id: "",
      image_time: "",

      // annotations
      list_of_annotations: [],
      list_of_bodyparts: [],
      list_of_keypoints: [],
      list_of_lines: [],

      has_contraction: false,
      list_of_contractions: [],

      has_activity: false,
      list_of_activities: [],

      has_age: false,
      list_of_ages: [],

      has_suckling: false,
      list_of_sucklings: [],

      topology38: [
        [7, 6],
        [12, 11],
        [15, 6],
        [15, 11],
        [6, 5],
        [11, 10],
        [10, 5],
        [10, 0],
        [5, 0],
        [3, 4],
        [1, 2],
        [27, 29],
        [29, 28],
        [24, 25],
        [25, 26],
        [15, 16],
        [16, 19],
        [19, 24],
        [19, 27],
        [19, 21],
        [21, 37],
        [21, 38],
        [37, 36],
        [38, 36],
        [21, 20],
        [20, 22],
        [22, 23],
        [20, 32],
        [20, 33],
        [33, 35],
        [35, 34],
        [32, 30],
        [30, 31],
        [0, 1],
        [3, 1],
        [4, 1],
        [5, 3],
        [10, 4],
      ],
      topology52: [
        [10, 5],
        [10, 11],
        [5, 6],
        [6, 7],
        [11, 12],
        [0, 5],
        [0, 10],
        [1, 2],
        [3, 4],
        [6, 15],
        [11, 15],
        [15, 16],
        [16, 19],
        [19, 20],
        [20, 21],
        [21, 22],
        [22, 23],
        [23, 24],
        [24, 25],
        [25, 26],
        [26, 27],
        [27, 28],
        [2, 17],
        [17, 18],
        [31, 32],
        [32, 30],
        [30, 29],
        [29, 33],
        [35, 37],
        [37, 36],
        [36, 34],
        [34, 38],
        [40, 42],
        [42, 39],
        [39, 41],
        [41, 43],
        [45, 47],
        [47, 46],
        [46, 44],
        [44, 48],
        [6, 8],
        [8, 9],
        [11, 13],
        [13, 14],
        [49, 50],
        [49, 51],
      ],
      showbos: false,
      bodyparts: false,
      keypoints: false,
      labels: false,
      show_contraction: false,
      show_activity: false,
      show_age: false,
      show_suckling: false,

      fullscreen: false,
      bbox_list: [],
      show_filtered: false,

      json_cache: {},
      init_load_ready: false,

      show_classification: false,
    };
  },
  watch: {
    showbos() {
      this.updateCanvas();
    },
    bodyparts() {
      this.updateCanvas();
    },
    keypoints() {
      this.updateCanvas();
    },
    labels() {
      if (this.labels) {
        this.showbos = true;
      } else {
        this.show_age = false;
      }

      this.updateCanvas();
    },
    show_contraction() {
      this.updateCanvas();
    },
    // show_activity() {
    //   this.updateCanvas();
    // },
    show_classification() {
      if (this.show_classification) {
        this.labels = true;
      }

      this.updateCanvas();
    },
    show_suckling() {
      this.updateCanvas();
    },
    show_filtered() {
      this.updateCanvas();
    },
    async img_src() {
      this.update_path();
    },
  },
  methods: {
    async update_path() {
      var canvas = this.$refs.mainCanvas;
      if (canvas) {
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      this.image_path = this.img_src;
      await this.fetchJSON();
      this.processJSON(this.json_data.frame["0"]);
      await this.$nextTick();
      this.updateCanvas();
    },

    previousImage() {
      if (this.json_loading) {
        return;
      }
      eventBus.$emit("previousImage", this.image_index);
    },
    nextImage() {
      if (this.json_loading) {
        return;
      }
      eventBus.$emit("nextImage", this.image_index);
    },
    close() {
      var canvas = this.$refs.mainCanvas;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$nextTick(() => {
        this.updateCanvas();
      });
    },
    fetchJSON() {
      // if(this.json_cache[this.image_path]){
      //   this.json_data = this.json_cache[this.image_path];
      //   return Promise.resolve();
      // }

      const url = this.image_path
        .replace("jpg", "json.zip")
        .replace("_event", "");
      this.json_loading = true;
      var zip = new JSZip();
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.loadAsync(data);
          })
          .then((zip) => {
            zip.forEach((relativePath, zipEntry) => {
              zipEntry
                .async("blob")
                .then((content) => {
                  return content.text();
                })
                .then((data) => {
                  this.json_data = JSON.parse(data);
                  this.json_loading = false;

                  // var keys = Object.keys(this.json_cache);
                  // if(keys.length>=5){
                  //   delete this.json_cache[keys[0]];
                  // }
                  // this.json_cache[this.image_path] = this.json_data;
                  resolve();
                });
            });
          });
      });
    },
    scale(coord) {
      var json_width = this.json_data.info.width;
      var canvas_width = this.$refs.mainCanvas.clientWidth;
      return Math.round(coord * (canvas_width / json_width));
    },
    processJSON(j_data) {
      this.list_of_annotations = [];
      this.list_of_bodyparts = [];
      this.list_of_keypoints = [];
      this.list_of_lines = [];
      this.list_of_contractions = [];
      this.list_of_activities = [];
      this.list_of_ages = [];
      this.list_of_sucklings = [];
      this.has_contraction = false;
      this.has_activity = false;
      this.has_age = false;
      this.has_suckling = false;

      var topology = [];
      if (j_data) {
        var len = j_data.length;
        for (let i = 0; i < len; i++) {
          if (Object.prototype.hasOwnProperty.call(j_data[i], "bodyparts")) {
            var len2 = j_data[i]["bodyparts"].length;
            for (let j = 0; j < len2; j++) {
              let bodybox = j_data[i]["bodyparts"][j]["bbox"];
              var txt2 = j_data[i]["bodyparts"][j]["label"];
              let conf = j_data[i]["bodyparts"][j]["conf"];
              var bodyPartsConfPercentage = Number(conf.replace("%", ""));
              if (txt2 == "cow") {
                if (bodyPartsConfPercentage >= 10) {
                  this.list_of_bodyparts.push({
                    x1: bodybox["x1"],
                    y1: bodybox["y1"],
                    w: bodybox["w"],
                    h: bodybox["h"],
                    label: txt2,
                    conf: conf,
                  });
                }
              } else {
                if (bodyPartsConfPercentage >= 10) {
                  this.list_of_bodyparts.push({
                    x1: bodybox["x1"],
                    y1: bodybox["y1"],
                    w: bodybox["w"],
                    h: bodybox["h"],
                    label: txt2,
                    conf: conf,
                  });
                }
              }
            }
          }
          if (Object.prototype.hasOwnProperty.call(j_data[i], "bodyparts_id")) {
            len2 = j_data[i]["bodyparts_id"].length;
            for (let j = 0; j < len2; j++) {
              let bodybox = j_data[i]["bodyparts_id"][j]["bbox"];
              txt2 = j_data[i]["bodyparts_id"][j]["label"];
              let conf = j_data[i]["bodyparts_id"][j]["conf"];
              bodyPartsConfPercentage = Number(conf.replace("%", ""));
              if (txt2 == "cow") {
                if (bodyPartsConfPercentage >= 10) {
                  this.list_of_bodyparts.push({
                    x1: bodybox["x1"],
                    y1: bodybox["y1"],
                    w: bodybox["w"],
                    h: bodybox["h"],
                    label: txt2,
                    conf: conf,
                  });
                }
              } else {
                if (bodyPartsConfPercentage >= 10) {
                  this.list_of_bodyparts.push({
                    x1: bodybox["x1"],
                    y1: bodybox["y1"],
                    w: bodybox["w"],
                    h: bodybox["h"],
                    label: txt2,
                    conf: conf,
                  });
                }
              }
            }
          }
          let box = j_data[i]["bbox"];
          var txt = j_data[i]["label"];
          var conf = j_data[i]["conf"];
          if (
            Object.prototype.hasOwnProperty.call(j_data[i], "classifications")
          ) {
            if (j_data[i]["classifications"][0]) {
              var classification = "";
              var classification_conf = "";
              if (
                Object.prototype.hasOwnProperty.call(
                  j_data[i]["classifications"][0],
                  "label"
                )
              ) {
                classification = j_data[i]["classifications"][0]["label"];
              }

              if (
                Object.prototype.hasOwnProperty.call(
                  j_data[i]["classifications"][0],
                  "conf"
                )
              ) {
                classification_conf = j_data[i]["classifications"][0]["conf"];
              }

              this.classificationDisabled = false;
            }
            for (let j = 0; j < j_data[i]["classifications"].length; j++) {
              if (j_data[i]["classifications"][j]?.type == "SGIE_CONTRACTION") {
                this.has_contraction = true;
                let c_label = j_data[i]["classifications"][j]?.label
                  ? `: ${j_data[i]["classifications"][j]?.label}`
                  : "";
                let c_conf = j_data[i]["classifications"][j]?.conf
                  ? `: ${j_data[i]["classifications"][j]?.conf.replace(
                      "%",
                      "°"
                    )}`
                  : "";
                this.list_of_contractions.push({
                  x1: box["x1"],
                  y1: box["y1"],
                  w: box["w"],
                  h: box["h"],
                  label: `contraction${c_label}${c_conf}`,
                  type: "contraction",
                });
              }

              if (
                j_data[i]["classifications"][j]?.type ==
                "SGIE_ACTIVITY_CLASSIFICATION"
              ) {
                this.has_activity = true;
                let c_label = j_data[i]["classifications"][j]?.label
                  ? `: ${j_data[i]["classifications"][j]?.label}`
                  : "";
                let c_conf = j_data[i]["classifications"][j]?.conf
                  ? `: ${j_data[i]["classifications"][j]?.conf}`
                  : "";
                this.list_of_activities.push({
                  x1: box["x1"],
                  y1: box["y1"],
                  w: box["w"],
                  h: box["h"],
                  label: `activity${c_label}${c_conf}`,
                  type: "activity",
                });
              }

              if (
                j_data[i]["classifications"][j]?.type ==
                "SGIE_AGE_CLASSIFICATION"
              ) {
                this.has_age = true;
                let c_label = j_data[i]["classifications"][j]?.label
                  ? `: ${j_data[i]["classifications"][j]?.label}`
                  : "";
                let c_conf = j_data[i]["classifications"][j]?.conf
                  ? `: ${j_data[i]["classifications"][j]?.conf}`
                  : "";
                this.list_of_ages.push({
                  x1: box["x1"],
                  y1: box["y1"],
                  w: box["w"],
                  h: box["h"],
                  label: `age${c_label}${c_conf}`,
                  type: "age",
                });
              }

              if (
                j_data[i]["classifications"][j]?.type ==
                "SGIE_SUCKLING_CLASSIFICATION"
              ) {
                this.has_suckling = true;
                let c_label = j_data[i]["classifications"][j]?.label
                  ? `: ${j_data[i]["classifications"][j]?.label}`
                  : "";
                let c_conf = j_data[i]["classifications"][j]?.conf
                  ? `: ${j_data[i]["classifications"][j]?.conf}`
                  : "";
                this.list_of_sucklings.push({
                  x1: box["x1"],
                  y1: box["y1"],
                  w: box["w"],
                  h: box["h"],
                  label: `suckling${c_label}${c_conf}`,
                  type: "suckling",
                });
              }
            }
          }

          var bboxConfPercentage = Number(conf.replace("%", ""));
          if (this.cFication) {
            var classificationConfPercentage = "";
            var classifications = "";

            if (classification_conf) {
              classificationConfPercentage = Number(
                classification_conf.replace("%", "")
              );

              classifications =
                classificationConfPercentage > 80 ? classification : "";
            }

            if (bboxConfPercentage >= 10) {
              this.list_of_annotations.push({
                x1: box["x1"],
                y1: box["y1"],
                w: box["w"],
                h: box["h"],
                label: txt,
                bboxconf: conf,
                classifications: classifications,
                classificationconf: classification_conf,
              });
            }
          } else {
            if (bboxConfPercentage >= 10) {
              this.list_of_annotations.push({
                x1: box["x1"],
                y1: box["y1"],
                w: box["w"],
                h: box["h"],
                label: txt,
                bboxconf: conf,
                classifications: "",
                classificationconf: "",
              });
            }
          }
          if (Object.prototype.hasOwnProperty.call(j_data[i], "keypoints")) {
            if (Object.keys(j_data[i]["keypoints"]).length == 52) {
              topology = this.topology52;
            } else {
              topology = this.topology38;
            }
            len2 = topology.length;
            let isHeadPresent = false;
            let isTailPresent = false;
            for (let j = 0; j < len2; j++) {
              let link1 = topology[j][0];
              let link2 = topology[j][1];
              if (
                // j_data[i]["keypoints"].length != 0 &&
                j_data[i]["keypoints"].length != 0
              ) {
                let coords1 = j_data[i]["keypoints"][link1]["coords"];
                let coords2 = j_data[i]["keypoints"][link2]["coords"];
                let linecolor = this.get_color(j);
                let bodyPartsArray = j_data[i]["bodyparts_id"];
                for (let index = 0; index < bodyPartsArray.length; index++) {
                  if (bodyPartsArray[index]["label"] === "head") {
                    isHeadPresent = true;
                  }
                  if (bodyPartsArray[index]["label"] === "tail") {
                    isTailPresent = true;
                  }
                }
                let exclude = [];
                if (!isHeadPresent) {
                  exclude = exclude.concat(this.getBodyPartArrayID("head"));
                }
                if (!isTailPresent) {
                  exclude = exclude.concat(this.getBodyPartArrayID("tail"));
                }
                if (!exclude.includes(j_data[i]["keypoints"][j]["id"])) {
                  this.list_of_lines.push({
                    x1: coords1["x1"],
                    y1: coords1["y1"],
                    x2: coords2["x1"],
                    y2: coords2["y1"],
                    linecolor: linecolor,
                  });
                  let pointcolor = this.get_point_color(link1);
                  pointcolor = this.get_point_color(link2);
                  this.list_of_keypoints.push({
                    x1: coords1["x1"],
                    y1: coords1["y1"],
                    color1: pointcolor[0],
                    color2: pointcolor[1],
                  });
                  this.list_of_keypoints.push({
                    x1: coords2["x1"],
                    y1: coords2["y1"],
                    color1: pointcolor[0],
                    color2: pointcolor[1],
                  });
                }
              }
            }
          }
        }
      }
    },
    drawBBOXByList(ctx, arr, fillStyle, strokeStyle) {
      for (let i = 0; i < arr.length; i++) {
        // filled rectangle
        var x1 = this.scale(arr[i].x1);
        var y1 = this.scale(arr[i].y1);
        //var w = this.scale(arr[i].w);
        //var h = this.scale(arr[i].h);
        ctx.fillStyle =
          this.$store.getters.getBBOXColorCode[arr[i].type]?.fillStyle ||
          fillStyle ||
          "rgba(242, 114, 0, 0)";
        //ctx.fillRect(x1, y1, w, h);
        // border modifications
        ctx.lineWidth = 1;
        ctx.strokeStyle =
          this.$store.getters.getBBOXColorCode[arr[i].type]?.strokeStyle ||
          strokeStyle ||
          "rgba(242, 114, 0)";
        //ctx.strokeRect(x1, y1, w, h);
        // draw text
        if (this.labels && arr[i].label) {
          ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          ctx.font = "12px Arial";
          var bboxText = arr[i].label;
          var textWidth = ctx.measureText(bboxText).width;
          ctx.fillRect(x1, y1 - 10, textWidth + 2, 10);
          ctx.fillStyle = "white";
          ctx.fillText(bboxText, x1, y1);
        }
      }
    },
    // startCanvas(){
    //   this.$nextTick(()=>{
    //       const resizeObserver = new ResizeObserver(() => {
    //           this.updateCanvas();
    //       });

    //       resizeObserver.observe(this.$refs.image);
    //       this.updateCanvas()
    //   });
    // },
    updateCanvas() {
      if (this.init_load_ready) {
        var canvas = this.$refs.mainCanvas;
      if (!canvas) {
        return;
      }
      canvas.width = this.$refs.mainCanvas.clientWidth;
      canvas.height = this.$refs.mainCanvas.clientHeight;
      // console.log(`w: ${this.$refs.mainCanvas.clientWidth}, h: ${this.$refs.mainCanvas.clientHeight}`)
      // console.log(`c_w: ${this.$refs.mainCanvas.clientWidth}, c_h: ${this.$refs.mainCanvas.clientHeight}`)
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
      if (this.showbos) {
        this.drawBBOX(ctx);
      }
      if (this.bodyparts) {
        this.drawBodyParts(ctx);
      }
      if (this.keypoints) {
        this.drawLines(ctx);
        this.drawKeypoints(ctx);
      }
      if (this.show_contraction) {
        this.drawBBOXByList(ctx, this.list_of_contractions);
      }

      // if (this.show_activity) {
      //   this.drawBBOXByList(ctx, this.list_of_activities);
      // }

      // if (this.show_age) {
      //   this.drawBBOXByList(ctx, this.list_of_ages);
      // }

      if (this.show_classification) {
        let temp_age_obj = {}
        for (const i in this.list_of_ages) {
          if (this.list_of_ages[i]) {
            temp_age_obj[this.list_of_ages[i].x1.toString() + this.list_of_ages[i].y1.toString()] = {...this.list_of_ages[i]}
          }
        }
        for (const i in this.list_of_activities) {
          if (this.list_of_activities[i]) {
            let activities_cord_key = this.list_of_activities[i].x1.toString() + this.list_of_activities[i].y1.toString()
            if (temp_age_obj[activities_cord_key]) {
              temp_age_obj[activities_cord_key].label = (temp_age_obj[activities_cord_key].label + ', ' + this.list_of_activities[i].label).replace('age: ', '').replace('activity: ', '').replace('_', ' ')
            } else {
              temp_age_obj[activities_cord_key] = this.list_of_activities[i]
              temp_age_obj[activities_cord_key].label = temp_age_obj[activities_cord_key].label.replace('age: ', '').replace('activity: ', '').replace('_', ' ')
            }
          }
        }

        let temp_classiication_array = []

        for (const n in temp_age_obj) {
          temp_classiication_array.push(temp_age_obj[n])
        }
        this.drawBBOXByList(ctx, temp_classiication_array);
      }

      if (this.show_suckling) {
        this.drawBBOXByList(ctx, this.list_of_sucklings);
      }
      if (this.show_filtered) {
        this.drawBBOXByList(
          ctx,
          this.bbox_list,
          "rgba(60, 179, 113, 0.4)",
          "rgba(60, 179, 113)"
        );
      }
      let temp_local_storage_params = JSON.stringify({
        body: this.showbos,
        bodyparts: this.bodyparts,
        keypoints: this.keypoints,
        labels: this.labels,
        classification: this.show_classification,
      });
      localStorage.setItem("image_view_pref", temp_local_storage_params);
      }
      
    },
    getBodyPartArrayID(str) {
      switch (str) {
        case "head":
          return [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
          ];
        case "tail":
          return [24, 25, 26, 27, 28];
        case "body":
          return [19, 20, 21, 22, 23, 33, 38, 43, 48, 49, 50, 51];
        case "limb":
          return [
            29, 30, 31, 32, 34, 35, 36, 37, 39, 40, 41, 42, 44, 45, 46, 47,
          ];
        default:
          return [];
      }
    },
    get_color(idx) {
      var colorDB = "(4, 24, 146)"; // dark blue
      var colorBrown = "(147, 82, 0)"; // brown
      var colorPink = "(148, 32, 147)"; // dark pink
      var colorGreen = "(3, 143, 0)"; // green
      var colorLB = "(3 84 148)"; // light blue
      var ret;
      if (idx <= 10) {
        ret = colorDB;
      } else if (idx >= 11 && idx <= 14) {
        ret = colorBrown;
      } else if ((idx >= 15 && idx <= 24) || idx == 27 || idx == 28) {
        ret = colorLB;
      } else if (idx >= 25 && idx <= 26) {
        ret = colorPink;
      } else if (idx >= 29 && idx <= 32) {
        ret = colorGreen;
      } else {
        ret = colorDB;
      }
      return ret;
    },
    get_point_color(idx) {
      var colorOrange = "(255, 147, 5)"; // orange
      var colorLightPink = "(255, 63, 255)"; // light pink
      var colorBrightGreen = "(13, 249, 0)"; // bright green
      var colorDarkSkyBlue = "(8 150 255)"; // little dark than sky blue
      var colorYellow = "(255, 255, 0)"; //  yellow
      //
      var colorDB = "(4, 24, 146)";
      var colorPink = "(148, 32, 147)";
      var colorGreen = "(3, 143, 0)";
      var colorLB = "(3 84 148)";
      var ret1, ret2;
      if (idx >= 2 && idx <= 4) {
        ret1 = colorYellow;
        ret2 = colorLB;
      } else if (idx == 5 || idx == 10 || (idx >= 24 && idx <= 29)) {
        ret1 = colorOrange;
        ret2 = colorLB;
      } else if (idx == 6 || idx == 11 || (idx >= 30 && idx <= 35)) {
        ret1 = colorBrightGreen;
        ret2 = colorGreen;
      } else if (idx == 7 || idx == 12 || idx == 22 || idx == 23) {
        ret1 = colorLightPink;
        ret2 = colorPink;
      } else {
        ret1 = colorDarkSkyBlue;
        ret2 = colorDB;
      }
      return [ret1, ret2];
    },
    drawBBOX(ctx) {
      for (let i = 0; i < this.list_of_annotations.length; i++) {
        var x1 = this.scale(this.list_of_annotations[i].x1);
        var y1 = this.scale(this.list_of_annotations[i].y1);
        var w = this.scale(this.list_of_annotations[i].w);
        var h = this.scale(this.list_of_annotations[i].h);
        // filled rectangle
        ctx.fillStyle = "rgba(242, 114, 0, 0.4)";
        ctx.fillRect(x1, y1, w, h);
        // border modifications
        ctx.lineWidth = 3;
        ctx.strokeStyle = "rgba(242, 114, 0)";
        ctx.strokeRect(x1, y1, w, h);
        if (this.labels) {
          ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          var bboxText =
            this.list_of_annotations[i].label +
            ", " +
            this.list_of_annotations[i].bboxconf;
          var textWidth = ctx.measureText(bboxText).width;
          ctx.fillRect(x1, y1 - 10, textWidth + 2, 10);
          ctx.fillStyle = "white";
          ctx.font = "12px Arial";
          ctx.fillText(bboxText, x1, y1);
          if (this.list_of_annotations[i].classifications != "") {
            ctx.fillStyle = "rgba(29, 107, 118,0.9)";
            var classificationText =
              this.list_of_annotations[i].classifications +
              ", " +
              this.list_of_annotations[i].classificationconf;
            textWidth = ctx.measureText(classificationText).width;
            ctx.fillRect(x1, y1, textWidth + 2, 10);
            ctx.fillStyle = "white";
            ctx.font = "12px Arial";
            ctx.fillText(classificationText, x1, y1 + 10);
          }
        }
      }
    },
    drawBodyParts(ctx) {
      for (let i = 0; i < this.list_of_bodyparts.length; i++) {
        var x1 = this.scale(this.list_of_bodyparts[i].x1);
        var y1 = this.scale(this.list_of_bodyparts[i].y1);
        var w = this.scale(this.list_of_bodyparts[i].w);
        var h = this.scale(this.list_of_bodyparts[i].h);
        // filled rectangle
        // ctx.fillStyle = "rgba(0, 154, 255, 0.3)";
        if (this.list_of_bodyparts[i].label == "tag") {
          ctx.fillStyle = "rgba(55, 255, 255, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "head") {
          ctx.fillStyle = "rgba(0, 154, 255, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "knee") {
          ctx.fillStyle = "rgba(254, 255, 0, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "hoof") {
          ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "tail") {
          ctx.fillStyle = "rgba(255, 66, 161, 0.4)";
        } else {
          ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
        }
        ctx.fillRect(x1, y1, w, h);
        // border modifications
        ctx.lineWidth = 3;
        if (this.list_of_bodyparts[i].label == "tag") {
          ctx.strokeStyle = "rgba(55, 255, 255, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "head") {
          ctx.strokeStyle = "rgba(0, 154, 255, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "knee") {
          ctx.strokeStyle = "rgba(254, 255, 0, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "hoof") {
          ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        } else if (this.list_of_bodyparts[i].label == "tail") {
          ctx.strokeStyle = "rgba(255, 66, 161, 0.4)";
        } else {
          ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        }
        // ctx.strokeStyle = "rgba(0, 154, 255)";
        ctx.strokeRect(x1, y1, w, h);
        if (this.labels) {
          ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          var bodyPartsText =
            this.list_of_bodyparts[i].label +
            ", " +
            this.list_of_bodyparts[i].conf;
          var textWidth = ctx.measureText(bodyPartsText).width;
          ctx.fillRect(x1, y1 - 10, textWidth + 2, 10);
          ctx.fillStyle = "white";
          ctx.font = "12px Arial";
          ctx.fillText(bodyPartsText, x1, y1);
        }
      }
    },
    drawLines(ctx) {
      for (let i = 0; i < this.list_of_lines.length; i++) {
        var x1 = this.scale(this.list_of_lines[i].x1);
        var y1 = this.scale(this.list_of_lines[i].y1);
        var x2 = this.scale(this.list_of_lines[i].x2);
        var y2 = this.scale(this.list_of_lines[i].y2);
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.lineWidth = 3;
        ctx.strokeStyle = "rgb" + this.list_of_lines[i].linecolor;
        ctx.stroke();
      }
    },
    drawKeypoints(ctx) {
      for (let i = 0; i < this.list_of_keypoints.length; i++) {
        var x1 = this.scale(this.list_of_keypoints[i].x1);
        var y1 = this.scale(this.list_of_keypoints[i].y1);
        ctx.strokeStyle = "rgb" + this.list_of_keypoints[i].color2;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.arc(x1, y1, 1, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fillStyle = "rgb" + this.list_of_keypoints[i].color1;
        ctx.fill();
      }
    },
    registerJSONElement() {},
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
  },
  created() {

    let temp_local_host_params = JSON.parse(localStorage.getItem("image_view_pref"));
    if (temp_local_host_params) {
      if (temp_local_host_params.body) {
        this.showbos = true
      }
      if (temp_local_host_params.bodyparts) {
        this.bodyparts = true
      }
      if (temp_local_host_params.keypoints) {
        this.keypoints = true
      }
      if (temp_local_host_params.labels) {
        this.labels = true
      }
      if (temp_local_host_params.classification) {
        this.show_classification = true
      }
    }
    this.init_load_ready = true
    this.update_path();
  },
};
</script>

<style>
.love_container {
  position: relative;
}

.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#image_card_annotation_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#image_card_main_canvas {
  width: 100%;
  height: calc(100% - 7px);
}
</style>