/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAgreement = /* GraphQL */ `
  subscription OnCreateAgreement($owner: String) {
    onCreateAgreement(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      agreement_type
      agreement_accepted
      agreement_version
      agreement_response_datetime
      agreement_response_ip
      agreement_document_url
      agreement_document_uri
      agreement_document_arn
      agreement_document_etag
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAgreement = /* GraphQL */ `
  subscription OnUpdateAgreement($owner: String) {
    onUpdateAgreement(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      agreement_type
      agreement_accepted
      agreement_version
      agreement_response_datetime
      agreement_response_ip
      agreement_document_url
      agreement_document_uri
      agreement_document_arn
      agreement_document_etag
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAgreement = /* GraphQL */ `
  subscription OnDeleteAgreement($owner: String) {
    onDeleteAgreement(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      agreement_type
      agreement_accepted
      agreement_version
      agreement_response_datetime
      agreement_response_ip
      agreement_document_url
      agreement_document_uri
      agreement_document_arn
      agreement_document_etag
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($owner: String) {
    onCreateCustomer(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_first_name
      customer_last_name
      customer_preferred_name
      customer_status
      customer_type
      customer_company_name
      customer_company_logo
      customer_company_brand_image
      customer_company_occupation
      customer_company_website
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_other
      customer_social_twitter
      customer_social_youtube
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_phone_work
      customer_referred_by
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        id
        email
        approved
        allowed_operations
      }
      agreements {
        nextToken
      }
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      carcasses {
        nextToken
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herds {
        nextToken
      }
      jetsons {
        nextToken
      }
      measurements {
        nextToken
      }
      notes {
        nextToken
      }
      phenotypes {
        nextToken
      }
      sites {
        nextToken
      }
      treatments {
        nextToken
      }
      videos {
        nextToken
      }
      wifi {
        nextToken
      }
      zones {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($owner: String) {
    onUpdateCustomer(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_first_name
      customer_last_name
      customer_preferred_name
      customer_status
      customer_type
      customer_company_name
      customer_company_logo
      customer_company_brand_image
      customer_company_occupation
      customer_company_website
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_other
      customer_social_twitter
      customer_social_youtube
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_phone_work
      customer_referred_by
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        id
        email
        approved
        allowed_operations
      }
      agreements {
        nextToken
      }
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      carcasses {
        nextToken
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herds {
        nextToken
      }
      jetsons {
        nextToken
      }
      measurements {
        nextToken
      }
      notes {
        nextToken
      }
      phenotypes {
        nextToken
      }
      sites {
        nextToken
      }
      treatments {
        nextToken
      }
      videos {
        nextToken
      }
      wifi {
        nextToken
      }
      zones {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer($owner: String) {
    onDeleteCustomer(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_first_name
      customer_last_name
      customer_preferred_name
      customer_status
      customer_type
      customer_company_name
      customer_company_logo
      customer_company_brand_image
      customer_company_occupation
      customer_company_website
      customer_social_instagram
      customer_social_facebook
      customer_social_linkedin
      customer_social_other
      customer_social_twitter
      customer_social_youtube
      customer_physical_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_mailing_address {
        address_1
        address_2
        city
        province
        postal_code
        country
      }
      customer_phone_home
      customer_phone_cell
      customer_phone_fax
      customer_phone_work
      customer_referred_by
      customer_collaborators {
        id
        email
        approved
        allowed_operations
      }
      customer_is_collaborator {
        id
        email
        approved
        allowed_operations
      }
      agreements {
        nextToken
      }
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      carcasses {
        nextToken
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herds {
        nextToken
      }
      jetsons {
        nextToken
      }
      measurements {
        nextToken
      }
      notes {
        nextToken
      }
      phenotypes {
        nextToken
      }
      sites {
        nextToken
      }
      treatments {
        nextToken
      }
      videos {
        nextToken
      }
      wifi {
        nextToken
      }
      zones {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSite = /* GraphQL */ `
  subscription OnCreateSite($owner: String) {
    onCreateSite(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      jetsons {
        nextToken
      }
      notes {
        nextToken
      }
      wifi {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSite = /* GraphQL */ `
  subscription OnUpdateSite($owner: String) {
    onUpdateSite(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      jetsons {
        nextToken
      }
      notes {
        nextToken
      }
      wifi {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSite = /* GraphQL */ `
  subscription OnDeleteSite($owner: String) {
    onDeleteSite(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_name
      site_assigned_id
      site_area
      site_community_pasture_used
      site_location {
        latitude
        longitude
      }
      site_map_zoom_level
      site_operation_type
      site_perimeter {
        latitude
        longitude
      }
      site_rfid_reader
      site_timezone
      site_weight_scale
      site_internet_provider
      site_public_ip
      site_isp_upload_mbps
      site_isp_download_mbps
      site_isp_datacap_gb
      site_isp_billing_cycle_day
      animals {
        nextToken
      }
      cameras {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      jetsons {
        nextToken
      }
      notes {
        nextToken
      }
      wifi {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZone = /* GraphQL */ `
  subscription OnCreateZone($owner: String) {
    onCreateZone(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZone = /* GraphQL */ `
  subscription OnUpdateZone($owner: String) {
    onUpdateZone(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZone = /* GraphQL */ `
  subscription OnDeleteZone($owner: String) {
    onDeleteZone(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      zone_type
      zone_area_square_meters
      zone_color
      zone_name
      zone_perimiter {
        latitude
        longitude
      }
      zone_perimeter_meters
      animals {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnimal = /* GraphQL */ `
  subscription OnCreateAnimal($owner: String) {
    onCreateAnimal(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status

      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatments {
        nextToken
      }
      phenotypes {
        nextToken
      }
      measurements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnimal = /* GraphQL */ `
  subscription OnUpdateAnimal($owner: String) {
    onUpdateAnimal(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatments {
        nextToken
      }
      phenotypes {
        nextToken
      }
      measurements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnimal = /* GraphQL */ `
  subscription OnDeleteAnimal($owner: String) {
    onDeleteAnimal(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      dam_id
      herd_id
      sire_id
      site_id
      surrogate_dam_id
      zone_id
      animal_sex
      animal_species
      animal_brand
      animal_breed
      animal_breeding_history {
        type
        start_date
        end_date
      }
      animal_breeding_soundness {
        date
        examiner
        result
        re_exam_date
        comment
        bcs
        hoof
        claw
        scrotum
        semen
        teat
        udder
        eyes
        leg
        prepuce
        prostate
        other
      }
      animal_classification
      animal_color
      animal_conception_type
      animal_dob
      animal_last_seen
      animal_image_body
      animal_image_head
      animal_keywords
      animal_name
      animal_ownership_status
      animal_pedigree {
        tattoo
        registered_name
        registration_association
        registration_number
        registration_date
        registration_doc
      }
      animal_pregnant
      animal_production_type
      animal_production_type_history {
        production_type
        end_date
      }
      animal_purpose
      animal_rfid_current
      animal_site_doa
      animal_cod
      animal_status
      animal_birth_ease
      animal_dam_status
      animal_disposal_code
      animal_site_dod
      animal_docility
      animal_nurse_info
      animal_vigor
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      groups {
        nextToken
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatments {
        nextToken
      }
      phenotypes {
        nextToken
      }
      measurements {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCarcass = /* GraphQL */ `
  subscription OnCreateCarcass($owner: String) {
    onCreateCarcass(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCarcass = /* GraphQL */ `
  subscription OnUpdateCarcass($owner: String) {
    onUpdateCarcass(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCarcass = /* GraphQL */ `
  subscription OnDeleteCarcass($owner: String) {
    onDeleteCarcass(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      animal_id
      carcass_slaughter_date
      carcass_base_price
      carcass_fat_thickness
      carcass_marbling_score
      carcass_maturity
      carcass_quality_grade
      carcass_ribeye_area
      carcass_us_bf
      carcass_us_marbling
      carcass_us_rea
      carcass_value
      carcass_weight_cold_kgs
      carcass_weight_cold_lbs
      carcass_weight_hot_kgs
      carcass_weight_hot_lbs
      carcass_yield_grade
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($owner: String) {
    onCreateGroup(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($owner: String) {
    onUpdateGroup(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($owner: String) {
    onDeleteGroup(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      group_name
      group_date
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnimalGroups = /* GraphQL */ `
  subscription OnCreateAnimalGroups($owner: String) {
    onCreateAnimalGroups(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnimalGroups = /* GraphQL */ `
  subscription OnUpdateAnimalGroups($owner: String) {
    onUpdateAnimalGroups(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnimalGroups = /* GraphQL */ `
  subscription OnDeleteAnimalGroups($owner: String) {
    onDeleteAnimalGroups(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      group_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHerd = /* GraphQL */ `
  subscription OnCreateHerd($owner: String) {
    onCreateHerd(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      herd_name
      herd_date
      herd_tag_color
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHerd = /* GraphQL */ `
  subscription OnUpdateHerd($owner: String) {
    onUpdateHerd(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      herd_name
      herd_date
      herd_tag_color
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHerd = /* GraphQL */ `
  subscription OnDeleteHerd($owner: String) {
    onDeleteHerd(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      herd_name
      herd_date
      herd_tag_color
      animals {
        nextToken
      }
      events {
        nextToken
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTreatment = /* GraphQL */ `
  subscription OnCreateTreatment($owner: String) {
    onCreateTreatment(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      treatment_date
      treatment_keywords
      treatment_type
      treatment_administered_drugs {
        administration_type
        administration_location
        dose
        lot_no
        name
        type
      }
      treatment_reason
      treatment_vet_contacted
      treatment_vet_name
      treatment_booster_date
      treatment_withdraw_date
      treatment_withdraw_days
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTreatment = /* GraphQL */ `
  subscription OnUpdateTreatment($owner: String) {
    onUpdateTreatment(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      treatment_date
      treatment_keywords
      treatment_type
      treatment_administered_drugs {
        administration_type
        administration_location
        dose
        lot_no
        name
        type
      }
      treatment_reason
      treatment_vet_contacted
      treatment_vet_name
      treatment_booster_date
      treatment_withdraw_date
      treatment_withdraw_days
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTreatment = /* GraphQL */ `
  subscription OnDeleteTreatment($owner: String) {
    onDeleteTreatment(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      treatment_date
      treatment_keywords
      treatment_type
      treatment_administered_drugs {
        administration_type
        administration_location
        dose
        lot_no
        name
        type
      }
      treatment_reason
      treatment_vet_contacted
      treatment_vet_name
      treatment_booster_date
      treatment_withdraw_date
      treatment_withdraw_days
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePhenotype = /* GraphQL */ `
  subscription OnCreatePhenotype($owner: String) {
    onCreatePhenotype(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      phenotype_date
      phenotype_score
      phenotype_type
      phenotype_image
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePhenotype = /* GraphQL */ `
  subscription OnUpdatePhenotype($owner: String) {
    onUpdatePhenotype(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      phenotype_date
      phenotype_score
      phenotype_type
      phenotype_image
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePhenotype = /* GraphQL */ `
  subscription OnDeletePhenotype($owner: String) {
    onDeletePhenotype(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      animal_id
      phenotype_date
      phenotype_score
      phenotype_type
      phenotype_image
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeasurement = /* GraphQL */ `
  subscription OnCreateMeasurement($owner: String) {
    onCreateMeasurement(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      measurement_date
      measurement_type
      measurement_unit
      measurement_value
      measurement_result
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeasurement = /* GraphQL */ `
  subscription OnUpdateMeasurement($owner: String) {
    onUpdateMeasurement(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      measurement_date
      measurement_type
      measurement_unit
      measurement_value
      measurement_result
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeasurement = /* GraphQL */ `
  subscription OnDeleteMeasurement($owner: String) {
    onDeleteMeasurement(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      measurement_date
      measurement_type
      measurement_unit
      measurement_value
      measurement_result
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCamera = /* GraphQL */ `
  subscription OnCreateCamera($owner: String) {
    onCreateCamera(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      camera_footage_thumbnail_url
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_inventory_number
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCamera = /* GraphQL */ `
  subscription OnUpdateCamera($owner: String) {
    onUpdateCamera(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      camera_footage_thumbnail_url
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_inventory_number
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCamera = /* GraphQL */ `
  subscription OnDeleteCamera($owner: String) {
    onDeleteCamera(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      camera_name
      camera_manufacturer
      camera_model
      camera_broken
      camera_broken_reason
      camera_cellular_enabled
      camera_stream_clear {
        resolution
        bitrate_kbps
        fps
      }
      camera_firmware_version
      camera_stream_fluent {
        resolution
        bitrate_kbps
        fps
      }
      camera_footage_thumbnail_url
      camera_ftp_enabled
      camera_hardware_no
      camera_imei
      camera_inventory_number
      camera_ip
      camera_last_status_update
      camera_last_video_upload
      camera_latest_upload
      camera_latest_upload_bitrate_kbps
      camera_latest_upload_corrupted
      camera_latest_upload_fps
      camera_latest_upload_resolution
      camera_latest_upload_source
      camera_latest_upload_type
      camera_location {
        latitude
        longitude
      }
      camera_mac
      camera_map_icon_color
      camera_reolinkcloud_enabled
      camera_s3_uri
      camera_s3_url
      camera_sd_card_size_gb
      camera_shipped
      camera_status
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      videos {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJetson = /* GraphQL */ `
  subscription OnCreateJetson($owner: String) {
    onCreateJetson(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJetson = /* GraphQL */ `
  subscription OnUpdateJetson($owner: String) {
    onUpdateJetson(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJetson = /* GraphQL */ `
  subscription OnDeleteJetson($owner: String) {
    onDeleteJetson(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      jetson_manufacturer
      jetson_thing_name
      jetson_type
      jetson_jfrog_device_id
      jetson_cpu_power
      jetson_cpu_usage
      jetson_disk_usage
      jetson_jetpack_version
      jetson_last_seen_jfrog
      jetson_location {
        latitude
        longitude
      }
      jetson_map_icon_color
      jetson_online_jfrog
      jetson_ram_usage
      jetson_shipped
      jetson_status
      jetson_jfrog_group
      jetson_jfrog_name
      jetson_jfrog_project
      jetson_jfrog_version
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWifi = /* GraphQL */ `
  subscription OnCreateWifi($owner: String) {
    onCreateWifi(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_inventory_number
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWifi = /* GraphQL */ `
  subscription OnUpdateWifi($owner: String) {
    onUpdateWifi(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_inventory_number
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWifi = /* GraphQL */ `
  subscription OnDeleteWifi($owner: String) {
    onDeleteWifi(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      site_id
      wifi_device_type
      wifi_manufacturer
      wifi_model
      wifi_download_speed_mbps
      wifi_email
      wifi_fixed
      wifi_inventory_number
      wifi_ip_lan
      wifi_ip_wan
      wifi_location {
        latitude
        longitude
      }
      wifi_mac
      wifi_map_icon_color
      wifi_mesh_speed
      wifi_ping_time
      wifi_serial_no
      wifi_serial_number
      wifi_shipped
      wifi_status
      wifi_upload_speed_mbps
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      notes {
        nextToken
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($owner: String) {
    onCreateNotification(owner: $owner) {
      id
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($owner: String) {
    onUpdateEvent(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      camera_id
      group_id
      herd_id
      jetson_id
      site_id
      video_id
      zone_id
      event_datetime_start
      event_datetime_end
      event_level
      event_title
      event_color
      event_type
      event_description
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      video {
        id
        owner
        updatedBy
        customer_id
        camera_id
        camera_id_datastore
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_size_mb
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($owner: String) {
    onDeleteEvent(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      animal_id
      customer_id
      camera_id
      group_id
      herd_id
      jetson_id
      site_id
      video_id
      zone_id
      event_datetime_start
      event_datetime_end
      event_level
      event_title
      event_color
      event_type
      event_description
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      video {
        id
        owner
        updatedBy
        customer_id
        camera_id
        camera_id_datastore
        video_datetime
        video_duration
        video_duration_seconds
        video_size
        video_size_float
        video_size_mb
        video_path
        video_source
        video_bitrate
        video_bitrate_kbps
        video_bookmarked
        video_fps
        video_height
        video_resolution
        video_width
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFeedback = /* GraphQL */ `
  subscription OnCreateFeedback($owner: String) {
    onCreateFeedback(owner: $owner) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFeedback = /* GraphQL */ `
  subscription OnUpdateFeedback($owner: String) {
    onUpdateFeedback(owner: $owner) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFeedback = /* GraphQL */ `
  subscription OnDeleteFeedback($owner: String) {
    onDeleteFeedback(owner: $owner) {
      id
      owner
      customer_id
      feedback_body
      feedback_type
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote($owner: String) {
    onCreateNote(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
      zone_id
      camera_id
      carcass_id
      group_id
      herd_id
      jetson_id
      site_id
      treatment_id
      phenotype_id
      measurement_id
      wifi_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatment {
        id
        owner
        updatedBy
        customer_id
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
      }
      phenotype {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
      }
      measurement {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
      }
      wifi {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
        wifi_manufacturer
        wifi_model
        wifi_download_speed_mbps
        wifi_email
        wifi_fixed
        wifi_inventory_number
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_map_icon_color
        wifi_mesh_speed
        wifi_ping_time
        wifi_serial_no
        wifi_serial_number
        wifi_shipped
        wifi_status
        wifi_upload_speed_mbps
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote($owner: String) {
    onUpdateNote(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
      zone_id
      camera_id
      carcass_id
      group_id
      herd_id
      jetson_id
      site_id
      treatment_id
      phenotype_id
      measurement_id
      wifi_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatment {
        id
        owner
        updatedBy
        customer_id
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
      }
      phenotype {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
      }
      measurement {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
      }
      wifi {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
        wifi_manufacturer
        wifi_model
        wifi_download_speed_mbps
        wifi_email
        wifi_fixed
        wifi_inventory_number
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_map_icon_color
        wifi_mesh_speed
        wifi_ping_time
        wifi_serial_no
        wifi_serial_number
        wifi_shipped
        wifi_status
        wifi_upload_speed_mbps
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote($owner: String) {
    onDeleteNote(owner: $owner) {
      id
      owner
      author {
        id
        first_name
        last_name
        email
      }
      updatedBy
      customer_id
      typeName
      note_datetime
      note_text
      animal_id
      zone_id
      camera_id
      carcass_id
      group_id
      herd_id
      jetson_id
      site_id
      treatment_id
      phenotype_id
      measurement_id
      wifi_id
      animal {
        id
        owner
        updatedBy
        customer_id
        dam_id
        herd_id
        sire_id
        site_id
        surrogate_dam_id
        zone_id
        animal_sex
        animal_species
        animal_brand
        animal_breed
        animal_classification
        animal_color
        animal_conception_type
        animal_dob
        animal_last_seen
        animal_image_body
        animal_image_head
        animal_keywords
        animal_name
        animal_ownership_status
        animal_pregnant
        animal_production_type
        animal_purpose
        animal_rfid_current
        animal_site_doa
        animal_cod
        animal_status
        animal_birth_ease
        animal_dam_status
        animal_disposal_code
        animal_site_dod
        animal_docility
        animal_nurse_info
        animal_vigor
        createdAt
        updatedAt
      }
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      group {
        id
        owner
        updatedBy
        customer_id
        group_name
        group_date
        createdAt
        updatedAt
      }
      herd {
        id
        owner
        updatedBy
        customer_id
        herd_name
        herd_date
        herd_tag_color
        createdAt
        updatedAt
      }
      jetson {
        id
        owner
        updatedBy
        customer_id
        site_id
        jetson_manufacturer
        jetson_thing_name
        jetson_type
        jetson_jfrog_device_id
        jetson_cpu_power
        jetson_cpu_usage
        jetson_disk_usage
        jetson_jetpack_version
        jetson_last_seen_jfrog
        jetson_map_icon_color
        jetson_online_jfrog
        jetson_ram_usage
        jetson_shipped
        jetson_status
        jetson_jfrog_group
        jetson_jfrog_name
        jetson_jfrog_project
        jetson_jfrog_version
        createdAt
        updatedAt
      }
      site {
        id
        owner
        updatedBy
        customer_id
        site_name
        site_assigned_id
        site_area
        site_community_pasture_used
        site_map_zoom_level
        site_operation_type
        site_rfid_reader
        site_timezone
        site_weight_scale
        site_internet_provider
        site_public_ip
        site_isp_upload_mbps
        site_isp_download_mbps
        site_isp_datacap_gb
        site_isp_billing_cycle_day
        createdAt
        updatedAt
      }
      treatment {
        id
        owner
        updatedBy
        customer_id
        animal_id
        treatment_date
        treatment_keywords
        treatment_type
        treatment_reason
        treatment_vet_contacted
        treatment_vet_name
        treatment_booster_date
        treatment_withdraw_date
        treatment_withdraw_days
        createdAt
        updatedAt
      }
      phenotype {
        id
        owner
        updatedBy
        customer_id
        animal_id
        phenotype_date
        phenotype_score
        phenotype_type
        phenotype_image
        createdAt
        updatedAt
      }
      measurement {
        id
        owner
        updatedBy
        animal_id
        customer_id
        measurement_date
        measurement_type
        measurement_unit
        measurement_value
        measurement_result
        createdAt
        updatedAt
      }
      wifi {
        id
        owner
        updatedBy
        customer_id
        site_id
        wifi_device_type
        wifi_manufacturer
        wifi_model
        wifi_download_speed_mbps
        wifi_email
        wifi_fixed
        wifi_inventory_number
        wifi_ip_lan
        wifi_ip_wan
        wifi_mac
        wifi_map_icon_color
        wifi_mesh_speed
        wifi_ping_time
        wifi_serial_no
        wifi_serial_number
        wifi_shipped
        wifi_status
        wifi_upload_speed_mbps
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo($owner: String) {
    onCreateVideo(owner: $owner) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      camera_id_datastore
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_size_mb
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo($owner: String) {
    onUpdateVideo(owner: $owner) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      camera_id_datastore
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_size_mb
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo($owner: String) {
    onDeleteVideo(owner: $owner) {
      id
      owner
      updatedBy
      customer_id
      camera_id
      camera_id_datastore
      video_datetime
      video_duration
      video_duration_seconds
      video_size
      video_size_float
      video_size_mb
      video_path
      video_source
      video_bitrate
      video_bitrate_kbps
      video_bookmarked
      video_fps
      video_height
      video_resolution
      video_width
      camera {
        id
        owner
        updatedBy
        customer_id
        site_id
        camera_name
        camera_manufacturer
        camera_model
        camera_broken
        camera_broken_reason
        camera_cellular_enabled
        camera_firmware_version
        camera_footage_thumbnail_url
        camera_ftp_enabled
        camera_hardware_no
        camera_imei
        camera_inventory_number
        camera_ip
        camera_last_status_update
        camera_last_video_upload
        camera_latest_upload
        camera_latest_upload_bitrate_kbps
        camera_latest_upload_corrupted
        camera_latest_upload_fps
        camera_latest_upload_resolution
        camera_latest_upload_source
        camera_latest_upload_type
        camera_mac
        camera_map_icon_color
        camera_reolinkcloud_enabled
        camera_s3_uri
        camera_s3_url
        camera_sd_card_size_gb
        camera_shipped
        camera_status
        createdAt
        updatedAt
      }
      customer {
        id
        owner
        updatedBy
        customer_first_name
        customer_last_name
        customer_preferred_name
        customer_status
        customer_type
        customer_company_name
        customer_company_logo
        customer_company_brand_image
        customer_company_occupation
        customer_company_website
        customer_social_instagram
        customer_social_facebook
        customer_social_linkedin
        customer_social_other
        customer_social_twitter
        customer_social_youtube
        customer_phone_home
        customer_phone_cell
        customer_phone_fax
        customer_phone_work
        customer_referred_by
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
