import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import * as VueGoogleMaps from 'vue2-google-maps'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBAWqigAHgLwMgexZJp2WCmCFK9H8OafI0',
    libraries: 'places, drawing',
  },
})

export const eventBus = new Vue();
Vue.config.productionTip = true;

// Create the Vue instance
const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  created() {
    this.$store.dispatch('fetchUser');
  },
}).$mount('#app');

// Expose the app instance for Cypress
if (window.Cypress) {
  window.app = app;
  
  // Mock AWS Amplify for testing
  window.Amplify = Amplify;
  
  // Expose store and router for testing
  window.store = store;
  window.router = router;
}