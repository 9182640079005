import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/main_pages.vue'),
    },
    {
      path: '/animals/:animal_index',
      name: 'animals',
      component: () => import('../views/livestock.vue'),
      props: true
    },
    {
      path: '/sharing',
      name: 'sharing',
      component: () => import('../views/collaborations.vue'),
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('../views/map.vue'),
    },
    {
      path: '/sites',
      name: 'site',
      component: () => import('../views/site_settings.vue'),
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('../views/user_settings.vue'),
      props: true,
    },
    {
      path: '/animal-dashboard',
      name: 'animal_dashboard',
      component: () => import('../views/main_pages.vue'),
      props: true,
    },
    {
      path: '/health-dashboard',
      name: 'health_dashboard',
      component: () => import('../views/main_pages.vue'),
      props: true,
    },
    {
      path: '/measurements-dashboard',
      name: 'measurements_dashboard',
      component: () => import('../views/main_pages.vue'),
      props: true,
    },
    {
      path: '/phenotype-dashboard',
      name: 'phenotype_dashboard',
      component: () => import('../views/main_pages.vue'),
      props: true,
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/login.vue'),
    },
    {
      path: '/notes',
      name: 'notes',
      component: () => import('../views/notes.vue'),
    },
    {
      path: '/videos/:cameraname/:trial',
      name: 'videos',
      component: () => import('../views/videos.vue'),
      props: true,
    },
    {
      path: '/images',
      name: 'images',
      component: () => import('../views/images.vue'),
      props: true,

    },
    {
      path: '/analytics/:trial',
      name: 'analytics',
      component: () => import('../views/analytics.vue'),
      props: true,
    },
    {
      path: '/heatmaps/:cameraname/:trial',
      name: 'heatmaps',
      component: () => import('../views/heatMapAnalytics.vue'),
      props: true,
    },
    {
      path: '/cameras',
      name: 'cameras',
      component: () => import('../views/videos.vue'),
      props: true,
    },
    {
      path: '/footageuploads',
      name: 'footageuploads',
      component: () => import('../views/main_pages.vue'),
    },
    {
      path: '/register/:register_type',
      name: 'register',
      component: () => import('../views/register.vue'),
      props: true,
    },
    {
      path: '*',
      name: 'not found',
      component: () => import('../views/404.vue'),
    },
  ],
});




router.beforeResolve((to, from, next) => {
  const authService = store.state.user;
  if (!(to.name == 'Login' || to.name == 'register') && authService == null) {
    next({ name: 'Login', query: { redirect: to.fullPath } })
  } 
  else next()

});

export default router;
