<template>
  <div>
    <!-- <h4 v-if="$globEventParams && $globEventParams.date" class="mb-4">
        <v-icon  class="icon" :color="notification_color">mdi-calendar</v-icon>
        {{ date_reformat($globEventParams.date.split('T')[0]) }} 
      </h4> -->
    <v-alert
      class="notification_text_card mb-0"
      elevation="1"
      v-if="selected_events.length == 0"
    >
      No events
    </v-alert>
    <v-alert
      style="cursor: pointer"
      @click="select_notificaiton(i, $event)"
      v-for="(event, i) in selected_events"
      :key="`${i}+${event}`"
      elevation="1"
      :border="i === selected_notification_index ? 'bottom' : undefined"
      colored-border
      :color="
        type == 'ALERT'
          ? 'oc_red'
          : type == 'WARNING'
          ? 'oc_orange'
          : 'oc_green'
      "
      class="notification_text_card mb-2 pa-3"
    >
      <v-row>
        <!-- <v-icon v-if="type == 'NOTICE'" class="icon" color="oc_green"
            >mdi-information-outline</v-icon
          >
          <v-icon v-if="type == 'WARNING'" class="icon" color="oc_yellow"
            >mdi-shield-alert-outline</v-icon
          >
          <v-icon v-if="type == 'ALERT'" class="icon" color="oc_red"
            >mdi-alert-outline</v-icon
          > -->

        <v-col cols="8" class="py-0 pr-0 pl-4">
          <b
            style="display: block; line-height: 0.9; font-size: 15px"
            class="text-oc_red"
          >
            {{ event.notification_title }}</b
          >
          <span style="color: grey; font-size: 14px; margin-top: -2px">
            <v-tooltip v-if="event.camera" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="nav_to_table(event.camera.id)"
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 17px; z-index: 20"
                  >mdi-cctv</v-icon
                >
              </template>
              <span>{{ event.camera.camera_name }}</span>
            </v-tooltip>
            <v-tooltip v-if="event.notification_twilio_sid" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                 
                  v-bind="attrs"
                  v-on="on"
                  class="ml-1"
                  style="font-size: 17px; z-index: 20"
                  >mdi-message-processing</v-icon
                >
              </template>
              <span>An SMS was sent out for this notification</span>
            </v-tooltip>
            <span class="ml-2" v-if="event.notification_datetime"
              >{{ convert_am_pm(event.notification_datetime.slice(11, 16)) }} -
              {{ age(event.notification_datetime) }} old
            </span>
            <span class="ml-2" v-else
              >-
            </span>
          </span>
          <span
            style="
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              line-height: 1.2;
              inline-size: 190px;
            "
          >
            {{ event.notification_description }}
          </span>
          <span
            style="
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              line-height: 1.2;
              inline-size: 190px;
            "
          >
            {{ event.notification_twilio_sid }}
          </span></v-col
        ><v-col cols="4" class="py-0"
          ><v-img
            style="border-radius: 4px"
            aspect-ratio="1"
            :src="cookie_bucket + event.notification_media_url"
        /></v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
//import events1 from '@/data/events1.json';
import { eventBus } from "../../main.js";
import moment from "moment";
//import Vue from 'vue';
export default {
  name: "events",
  props: ["kind", "type"],
  data: () => ({
    sortedEvents: [],
    message: String,
    events: [],
    search_string: "",
    selected_items: [],
    selected_dates: [],
    notification_color: null,
    selected_notification_index: false,
  }),
  methods: {
    convert_am_pm(time) {
      return moment(time, "HH:mm").format("hh:mm A");
    },
    nav_to_table(device_id) {
      this.$router.push({
        name: "videos",
        params: {
          cameraname: device_id,
        },
      });
    },
    age(date) {
      if (date) {
        if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "seconds"
            ) +
            " " +
            "second"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) < 60
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "seconds"
            ) +
            " " +
            "seconds"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "minutes"
            ) +
            " " +
            "minute"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) < 60
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "minutes"
            ) +
            " " +
            "minutes"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "hours"
            ) +
            " " +
            "hour"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) < 24
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "hours"
            ) +
            " " +
            "hours"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "days"
            ) +
            " " +
            "day"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) < 30
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "days"
            ) +
            " " +
            "days"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "months"
            ) +
            " " +
            "month"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) < 12
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "months"
            ) +
            " " +
            "months"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) <= 24
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "years"
            ) +
            " " +
            "year"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) > 24
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "years"
            ) +
            " " +
            "years"
          );
        } else {
          return [false, false];
        }
      }
    },
    select_notificaiton(notification, event) {
      if (!event.target.className.includes("cctv")) {
        this.selected_notification_index = notification;
        eventBus.$emit("open_notification", notification);
      }
    },
    items_filter() {
      this.$store.dispatch("DDB_GET_NOTIFICATIONS", this.$globEventParams);
    },

    weekday(date) {
      var dayofweek;
      if (moment().diff(moment(date, "YYYY-MM-DD"), "days") == 0) {
        dayofweek = "Today";
      } else if (moment().diff(moment(date, "YYYY-MM-DD"), "days") == 1) {
        dayofweek = "Yesterday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 0) {
        dayofweek = "Sunday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 1) {
        dayofweek = "Monday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 2) {
        dayofweek = "Tuesday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 3) {
        dayofweek = "Wednesday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 4) {
        dayofweek = "Thursday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 5) {
        dayofweek = "Friday";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 6) {
        dayofweek = "Saturday";
      }
      return dayofweek;
    },

    date_reformat(date) {
      return moment(date, "YYYY-MM-DD").format("YYYY-MMM-DD");
    },
  },

  created() {
    eventBus.$on("change_notification_index", (index) => {
      this.selected_notification_index = index;
    });

    if (this.type == "NOTICE") {
      this.notification_color = "green";
    } else if (this.type == "WARNING") {
      this.notification_color = "rgb(241, 198, 24)";
    } else if (this.type == "ALERT") {
      this.notification_color = "red";
    }
    eventBus.$on("items_filter", () => this.items_filter());
  },
  watch: {},
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    date() {
      if (this.$globEventParams && this.$globEventParams.date) {
        return this.date_reformat(this.$globEventParams.date.split("T")[0]);
      } else return moment().format("YYYY-MMM-DD");
    },
    selected_events() {
      if (this.$store.getters.getEvents) {
        // var date_events = {};
        // if (!this.$globEventParams.date) {
        //   let i = 0;
        //   while (i < 14) {
        //     let date = moment().subtract(i, "days").format("YYYY-MM-DD");
        //     date_events[date] = [];
        //     for (const n in this.$store.getters.getEvents) {
        //       if (
        //         this.$store.getters.getEvents[n].notification_datetime_start
        //           .toString()
        //           .slice(0, 10) == date
        //       ) {
        //         date_events[date].push(
        //           this.$store.getters.getEvents[n].notification_title
        //         );
        //       }
        //     }
        //     i++;
        //   }
        //   // if (this.$globEventParams.search_str.length || this.$globEventParams.params.filter.or) {
        //   //   for (const y in date_events) {
        //   //     if (date_events[y].length == 0) {
        //   //       delete date_events[y];
        //   //     }
        //   //   }
        //   // }
        // } else {
        //   date_events[this.$globEventParams.date] = [];
        //   for (const x in this.$store.getters.getEvents) {
        //     date_events[this.$globEventParams.date].push(
        //       this.$store.getters.getEvents[x].notification_title
        //     );
        //   }
        // }
        // return date_events;
        if (this.type == "ALERT") {
          return this.$store.getters.getEvents.alerts;
        } else if (this.type == "WARNING") {
          return this.$store.getters.getEvents.warnings;
        } else {
          return this.$store.getters.getEvents.notices;
        }
      } else {
        return false;
      }
    },
  },
  // mounted() {
  //   this.fetchEvents();
  //   //this.getLength();
  // },
};
</script>

<style lang="css" scoped>
.icon {
  margin-right: 4px;
}
.eventcards {
  margin: 0;
}

.notification_text_card {
  min-width: 309px;
  max-width: 309px;
}
.notification_text_card > div > div {
  width: 100%;
}
#item_selector
  > .v-input
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections {
  max-height: 50px;
}
</style>

